/* eslint-disable import/named */
import { AxiosResponse } from 'axios'
import { HttpCode, HttpError } from './http.model'
import { authenticationService } from '../services/authentication.service'
import { store } from '../../store'
import { clearToken } from '../../areas/auth/auth.slice'
import { clear } from '../../areas/user/user.slice'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const successHandler = (response: AxiosResponse<any>): AxiosResponse<any> => {
  return response
}

const errorHandler = (error: HttpError) => {
  if (error) {
    if (error.response.status === HttpCode.UNAUTHORIZED) {
      authenticationService.removeToken()
      store.dispatch(clear())
      store.dispatch(clearToken())
      //     if (window.location.pathname !== '/auth/signin') {
      //       window.location.href = '/auth/signin';
      //     }
      return null
    }

    console.log('An error occurred')
  }

  return Promise.reject({ ...error })
}

export { errorHandler, successHandler }
