/* eslint-disable @typescript-eslint/no-explicit-any */
import L from 'leaflet'
import { SelectedObject, SelectedObjectType } from '../aside/aside.model'
import { Flag, FlagType } from '../flags/flags.model'
import { ActivationSpot } from './spots.model'
import { Const } from '../../../common'

const getRadius = (zoom: number) => {
  if (zoom >= 10) {
    return 8
  } else {
    return 6
  }
}

const getTooltipText = (spot: ActivationSpot) => {
  const chips = [...spot.metadata.accessMethods, ...spot.metadata.activationMethods, ...spot.metadata.facilities]
  const renderedChips = chips.map(chip => `<span class="spot-metadata-chip">${chip}</span>`)
  const tooltip = `<div><b>${spot.reference}</b> ${spot.name}<div/><div>${renderedChips.join('')}</div>`
  return tooltip
}

const circleWithText = (spot: ActivationSpot, selectedFlags: FlagType[], txt: string, circleOptions: any, onClick: any) => {
  if (txt === '12344') throw new Error('dummy')

  const flagsContainer = `<div class="flags-container">
    ${selectedFlags.some(x => x === FlagType.BOOKMARK) ? '<div class="bookmark"></div>' : ''}
    ${selectedFlags.some(x => x === FlagType.ACTIVATED) ? '<div class="activated"></div>' : ''}
    ${selectedFlags.some(x => x === FlagType.HUNTED) ? '<div class="hunted"></div>' : ''}
  </div>`

  const icon = L.divIcon({
    html:
      `<?xml version="1.0" encoding="utf-8"?>
    <!-- Generator: Adobe Illustrator 24.1.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
    <style type="text/css">
      .st0{fill:#333333;}
    </style>
    <g id="XMLID_1_">
      <circle id="XMLID_3_" class="st0" cx="256" cy="256" r="77.2"/>
      <path id="XMLID_6_" class="st0" d="M512,230.8h-52.8c-11.8-92.9-85.1-166.2-177.2-178V0h-51.2v52.8c-92.9,11.8-166.2,85.1-178,178
        H0V282h52.8c11.8,92.2,85.1,165.4,178,177.2V512H282v-52.8c92.2-11.8,165.4-85.1,177.2-177.2H512V230.8z M256,409.6
        c-85.1,0-153.6-68.5-153.6-153.6S170.9,102.4,256,102.4S409.6,170.9,409.6,256S341.1,409.6,256,409.6z"/>
    </g>
    </svg>` + flagsContainer,
    className: '',
    iconSize: [20, 20],
  })
  const circle = L.circleMarker([spot.coords.coordinates[1], spot.coords.coordinates[0]], circleOptions)
  const marker = L.marker([spot.coords.coordinates[1], spot.coords.coordinates[0]], {
    icon: icon,
  })
    .addEventListener('click', () => onClick(spot))
    .bindTooltip(getTooltipText(spot))
  const group = L.layerGroup([circle, marker])

  return group
}

const getMarkerForSpot = (spot: ActivationSpot, zoom: number, selectedObject: SelectedObject | null | undefined, flags: Flag[], onClick: any) => {
  const isSelected = selectedObject && selectedObject.type === SelectedObjectType.SPOT && selectedObject.reference === spot.reference
  const className: string = `spot-point-base spot-point-${zoom} ${isSelected ? 'spot-selected' : ''}`
  const selectedFlags = flags.filter(x => x.reference === spot.reference && x.object === SelectedObjectType.SPOT).map(x => x.type)

  // return L.circleMarker([spot.coords.coordinates[1], park.coords.coordinates[0]], { radius: 3, className })
  return circleWithText(
    spot,
    selectedFlags,
    '',
    {
      radius: getRadius(zoom),
      className,
      weight: 2,
      color: Const.colors.parkPointBackgroundColor,
    },
    onClick
  )
}

export const spotsService = {
  getMarkerForSpot,
}
