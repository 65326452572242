import BottomNavigation from '@mui/material/BottomNavigation'
import styled from 'styled-components'

export const AsidePanelContainer = styled.div`
  display: flex;
  flex-basis: 412px;
  flex-direction: column;
  justify-content: stretch;
  height: 100vh;
`

export const AsideContentContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-bottom: 0.5rem;
  box-shadow: inset 0 7px 9px -7px rgba(0, 0, 0, 0.7);

  .details-background {
    position: relative;
    min-height: 400px;
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: 160px -80px;
    padding: 0.5rem;

    h1 {
      margin-top: 0.5rem;
      margin-bottom: 0;
    }
    h2 {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
`

export const AsideTopContainer = styled.div`
  height: 300px;
  background-size: cover;

  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  &:hover {
    cursor: pointer;
  }
  & > div {
    /* opacity: 1; */
    /* border: 1px solid red; */
    padding: 1rem;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
`

export const AsideBottomContainer = styled.div`
  height: 4.5rem;
`

export const BottomNavigationStyled = styled(BottomNavigation)`
  .MuiButtonBase-root {
    border-top: 1px solid lightgrey;
    width: 100%;
    height: 4.5rem;
    transition: 0.3s ease-in-out all;

    &:hover {
      color: #1976d2;
    }
    .MuiBottomNavigationAction-label {
      margin-top: 0.25rem;
    }
  }
`

export const LoadingContainer = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:before {
    position: absolute;
    content: '';
    background-color: rgba(255, 255, 255, 0.7);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  & * {
    z-index: 2;
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`
