import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { SelectedObject } from './aside.model'
import { GeoPoint } from '../../../common/models/geo.model'
// import { http } from '../../../common/http/http.service'

export interface AsideState {
  isOpen: boolean
  selectedObject?: SelectedObject | null
}

const initialState: AsideState = {
  isOpen: false,
  selectedObject: null,
}

export const asideState = createSlice({
  name: 'aside',
  initialState,
  reducers: {
    setSelectedObject: (state: AsideState, action: PayloadAction<SelectedObject>) => {
      const { payload } = action
      if (state.selectedObject && state.selectedObject.reference === payload.reference) {
        state.isOpen = false
        state.selectedObject = null
      } else {
        state.isOpen = true
        state.selectedObject = payload
      }
    },
    updateSelectedObjectGeo: (state: AsideState, action: PayloadAction<GeoPoint>) => {
      const { payload } = action
      if (state.selectedObject) {
        state.selectedObject.geo = payload
      }
    },
    clearSelectedObject: (state: AsideState) => {
      state.isOpen = false
      state.selectedObject = null
    },
    open: (state: AsideState) => {
      state.isOpen = true
    },
    close: (state: AsideState) => {
      state.isOpen = false
    },
  },
})

export const { open, close, setSelectedObject, clearSelectedObject, updateSelectedObjectGeo } = asideState.actions

export default asideState.reducer
