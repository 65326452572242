import { useDispatch, useSelector } from 'react-redux'
import { PointResult, ResultType } from '../../../search/search.model'
import { getPointResultsSelector } from '../../../search/search.selector'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { InfoChip } from '../../../../../common/components'
import Alert from '@mui/material/Alert'
import styled from 'styled-components'
import { setSelectedObject } from '../../aside.slice'
import { SelectedObjectType } from '../../aside.model'

const TableRowStyled = styled(TableRow)`
  &:hover {
    background-color: #ececec;
    cursor: pointer;
  }

  td {
    padding: 6px 1rem;
  }
`

const getAccordionLabel = (key: string, itemsLength: number) => {
  const description = Object.values(ResultType)[Object.keys(ResultType).indexOf(key)]
  const charactersCase =
    description.split('_').length === 1
      ? `${description.charAt(0)}${description.slice(1).toLowerCase()}`
      : `${description.split('_')[0]} ${description.split('_')[1].toLowerCase()}`
  const plural = itemsLength === 1 ? '' : 's'

  return `${charactersCase}${plural}`.replace('_', ' ')
}

export const PointResultsComponent = () => {
  const dispatch = useDispatch()
  const pointResults = useSelector(getPointResultsSelector)

  const handleRowClick = (item: PointResult) => {
    // console.log(item.coords)
    dispatch(
      setSelectedObject({
        type: SelectedObjectType.PARK,
        reference: item.reference,
        name: item.name,
        geo: item.coords.coordinates,
      })
    )
  }

  return (
    <div>
      {Object.keys(ResultType).map(resultType => {
        const items = pointResults.filter(x => x.type === resultType)
        const hasItems: boolean = !!items.length
        // console.log(pointResults)
        // console.log(items)
        return (
          <div key={resultType}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-expanded>
                <b style={{ marginRight: '0.5rem' }}>{getAccordionLabel(resultType, items.length)}</b>
                {hasItems && <InfoChip text={items.length.toString()} />}
              </AccordionSummary>
              <AccordionDetails sx={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
                <Table aria-label="table">
                  <TableBody>
                    {hasItems ? (
                      items.map((item: PointResult) => (
                        <TableRowStyled key={`${resultType}${item.reference}`} onClick={() => handleRowClick(item)}>
                          <TableCell align="left">
                            <b>{item.reference}</b> {item.name}
                          </TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRowStyled>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell align="left" sx={{ pt: 0 }}>
                          <Alert severity="info">There are no {getAccordionLabel(resultType, 0).toLowerCase()} for selected point</Alert>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </AccordionDetails>
            </Accordion>
          </div>
        )
      })}
    </div>
  )
}
