// import { useSelector } from 'react-redux'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
// import { InfoChip } from '../../../../common/components'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccordionDetails from '@mui/material/AccordionDetails'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
// import TableRow from '@mui/material/TableRow'
// import TableCell from '@mui/material/TableCell'

export const ParkActivationSpotsComponent = () => {
  // const parkRelations = useSelector(selectedParkRelationsSelector)
  // if (!parkRelations || !parkRelations.length) return

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <b style={{ marginRight: '0.5rem' }}>Activation spots</b>
        {/* <InfoChip text={`${activations.length} ${activations.length === 1 ? 'activation' : 'activations'}`} /> */}
        {/* <InfoChip text={parkRelations.length.toString()} /> */}
      </AccordionSummary>
      <AccordionDetails sx={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
        <Table aria-label="table" size="small">
          <TableBody>
            {/* {parkRelations.map((relation: ParkRelation) => {
                return (
                  <TableRow key={relation.reference}>
                    <TableCell>
                      <b>{relation.reference}</b>, Reference type: <b>{Object.values(ParkRelationType)[Object.keys(ParkRelationType).indexOf(relation.type)]}</b>
                      <br />
                      {relation.name}
                    </TableCell>
                  </TableRow>
                )
              })} */}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}
