import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'

import asideReducer from './areas/map/aside/aside.slice'
import authReducer from './areas/auth/auth.slice'
import commentReducer from './areas/map/aside/components/comment/comment.slice'
import countriesReducer from './areas/map/countries/countries.slice'
import flagsReducer from './areas/map/flags/flags.slice'
import mapReducer from './areas/map/map.slice'
import parksReducer from './areas/map/parks/parks.slice'
import searchReducer from './areas/map/search/search.slice'
import spotsReducer from './areas/map/spots/spots.slice'
import summitsReducer from './areas/map/summits/summits.slice'
import userReducer from './areas/user/user.slice'
import voivodeshipsReducer from './areas/map/voivodeships/voivodeships.slice'

const logger = createLogger({
  collapsed: true,
  timestamp: true,
})

const isLocalhost = window.location.href.includes('localhost')

export const createStore = () =>
  configureStore({
    reducer: combineReducers({
      aside: asideReducer,
      auth: authReducer,
      comment: commentReducer,
      countries: countriesReducer,
      flags: flagsReducer,
      map: mapReducer,
      parks: parksReducer,
      search: searchReducer,
      spots: spotsReducer,
      summits: summitsReducer,
      user: userReducer,
      voivodeships: voivodeshipsReducer,
    }),
    devTools: true,
    middleware: getDefaultMiddleware => {
      const middlewares = getDefaultMiddleware({
        serializableCheck: false,
      })

      if (isLocalhost) {
        middlewares.push(logger)
      }

      return middlewares
    },
  })

export const store = createStore()

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
