import axios from 'axios'
import { errorHandler, successHandler } from './http.interceptors'
import { ApiUrl } from '../services/config.service'
import { authenticationService } from '../services/authentication.service'

const http = () => {
  // console.log('http')
  const authorization = authenticationService.isTokenValid() ? { Authorization: `Bearer ${authenticationService.getToken()}` } : undefined
  const params = {
    headers: {
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin': '*',
      // 'Access-Control-Allow-Methods': 'GET,POST,PATCH,DELETE,OPTIONS',
      ...authorization,
    },
  }

  const instance = axios.create(params)

  instance.interceptors.request.use(config => {
    // console.log('interceptors config', config)
    // console.log('interceptors ApiUrl', ApiUrl())
    config.baseURL = ApiUrl()
    return config
  })

  instance.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
  )

  return instance
}

export { http }
