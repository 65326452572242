/* eslint-disable @typescript-eslint/no-explicit-any */
import L from 'leaflet'
import { Flag, FlagType } from '../flags/flags.model'
import { SelectedObject, SelectedObjectType } from '../aside/aside.model'
import { Country } from './countries.model'

const getAreaForCountry = (country: Country, zoom: number, selectedObject: SelectedObject | null | undefined, flags: Flag[], onClick: any) => {
  // const className: string = `park-area-base park-area-${zoom} ${park.activations ? '' : 'park-not-activated'}`
  if (!zoom) {
    console.log('TODO: not zoom')
  }

  const geoJsonFeature: any = {
    type: 'Feature',
    properties: {
      name: country.prefix,
    },
    geometry: country.geo,
  }

  const getParksInfo = () => {
    if (zoom === 5) {
      return `<p>
        Parks <span class="activated">${country.parksCount}</span>
        <span class="not-activated">${country.parksNotActivatedCount}</span>
      </p>`
    } else if (zoom === 6) {
      return `<p>
        Total parks <span class="activated">${country.parksCount}</span>
        never activated <span class="not-activated">${country.parksNotActivatedCount}</span>
      </p>`
    } else return ''
  }

  const getSummitsInfo = () => {
    if (country.summitsCount === 0) {
      return ''
    }

    if (zoom === 5) {
      return `<p>
        Summits <span class="activated">${country.summitsCount}</span>
        ${country.summitsNotActivatedCount ? '<span class="not-activated">${country.summitsNotActivatedCount}</span>' : ''}
      </p>`
    } else if (zoom === 6) {
      return `<p>
        Total summits <span class="activated">${country.summitsCount}</span>
        ${country.summitsNotActivatedCount ? '<span class="not-activated">${country.summitsNotActivatedCount}</span>' : 'all activated'}
      </p>`
    }
    return ''
  }

  const getCountryName = () => {
    if (zoom >= 5 && zoom <= 6) {
      return `<h2>${country.country}</h2>`
    }
    return ''
  }

  const getAreaFillColor = () => {
    if (selectedObject?.type === SelectedObjectType.COUNTRY && country.prefix === selectedObject?.reference) {
      return 'blue'
    }
    if (flags.some(f => f.reference === country.prefix && f.object === SelectedObjectType.COUNTRY && f.type === FlagType.BOOKMARK)) {
      return 'orange'
    }
    if (flags.some(f => f.reference === country.prefix && f.object === SelectedObjectType.COUNTRY && f.type === FlagType.ACTIVATED)) {
      return 'green'
    }
    if (flags.some(f => f.reference === country.prefix && f.object === SelectedObjectType.COUNTRY && f.type === FlagType.HUNTED)) {
      return 'deep-purple'
    }
    return 'gray'
  }

  const geoJSONStyles = () => {
    return {
      fillColor: getAreaFillColor(),
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.2,
    }
  }

  return (
    L.geoJSON(geoJsonFeature, { style: geoJSONStyles() })
      .addEventListener('click', () => onClick(country))
      // .addEventListener('', () => onClick(park))
      // .bindTooltip(getTooltipText(park))
      .bindTooltip(
        `<div>
        <h1>${country.prefix}</h1>
        ${getCountryName()}
        ${getParksInfo()}
        ${getSummitsInfo()}
      </div>`,
        { permanent: true, direction: 'center', className: 'country-label' }
      )
  )
}

export const countriesService = {
  getAreaForCountry,
}
