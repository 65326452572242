/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  z-index: 5000;
  left: 250px;
  top: 70px;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 8px;
  width: 400px;
  height: 40%;

  .list {
    margin: 12px 0;
    height: calc(100% - 50px);
    overflow-y: scroll;
  }

  .country {
    padding: 6px;
    font-family: 'Exo 2', sans-serif;
    font-size: 13px;

    &:hover {
      background-color: lightyellow;
      cursor: pointer;
    }

    &.current {
      color: red;
    }

    span {
      padding: 2px 6px;
      font-weight: 600;
      border-radius: 4px;
    }

    .nope {
      color: #666;
      background-color: lightgray;
    }

    .yup {
      color: green;
      background-color: lightgreen;
    }
  }
`

export const CountriesList = ({ countries, onCountryClick, selectedCountry }: any) => {
  const [searchTerm, setSearchTerm] = useState('')

  if (!countries?.length) return
  const filtered = countries.filter(
    (c: any) => c.country.toLowerCase().includes(searchTerm.toLowerCase()) || c.prefix.toLowerCase().includes(searchTerm.toLowerCase())
  )
  return (
    <Container>
      <div style={{ padding: '8px 4px', fontSize: '12px' }}>
        <input value={searchTerm} onChange={(e: any) => setSearchTerm(e.target.value)} />
        <button onClick={() => setSearchTerm('')}>clear</button>
      </div>
      <div className="list">
        {filtered &&
          filtered.map((country: any) => (
            <div
              key={country.prefix}
              className={`country ${country.prefix === selectedCountry?.prefix ? 'current' : ''}`}
              onClick={() => onCountryClick(country)}
            >
              {country.country} &nbsp;
              <strong>{country.prefix}</strong> &nbsp;
              {country.has_area ? <span className="yup">YES</span> : <span className="nope">area not set</span>}
            </div>
          ))}
      </div>
    </Container>
  )
}
