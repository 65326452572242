/* eslint-disable @typescript-eslint/no-explicit-any */
import * as L from 'leaflet'
import { Summit } from './summits.model'
import { SelectedObject, SelectedObjectType } from '../aside/aside.model'
import { Flag, FlagType } from '../flags/flags.model'

// const TriangleIcon = L.Icon.extend({
//   options: {
//     iconUrl: 'triangle.svg',
//     iconSize:     [38, 95],
//     shadowSize:   [50, 64],
//     iconAnchor:   [22, 94],
//     shadowAnchor: [4, 62],
//     popupAnchor:  [-3, -76]
//   }
// })
const svgTemplate = `
  <svg viewBox="0 0 100 100">
    <polygon points="50 15, 100 100, 0 100"/>
  </svg>
`

const getTooltipText = (summit: Summit) => {
  const activationsCount = summit.activationCount ? `${summit.activationCount} activations` : 'Never activated'
  const tooltip = `
    <b>${summit.summitCode}</b> ${summit.name}
    <br />
    Elevation: ${summit.altM} m / ${summit.altFt} ft - Points: ${summit.points}
    <br />
    ${activationsCount}
    `
  return tooltip
}

const getMarkerForSummit = (summit: Summit, zoom: number, selectedObject: SelectedObject | null | undefined, flags: Flag[], onClick: any) => {
  const selectedCss = summit.summitCode === selectedObject?.reference ? 'selected' : ''
  const selectedFlags = flags.filter(x => x.reference === summit.summitCode && x.object === SelectedObjectType.SUMMIT).map(x => x.type)

  const flagsContainer = `<div class="flags-container">
    ${selectedFlags.some(x => x === FlagType.BOOKMARK) ? '<div class="bookmark"></div>' : ''}
    ${selectedFlags.some(x => x === FlagType.ACTIVATED) ? '<div class="activated"></div>' : ''}
    ${selectedFlags.some(x => x === FlagType.HUNTED) ? '<div class="hunted"></div>' : ''}
  </div>`
  const className: string = `summit-point-base summit-point-${zoom} summit-weight-${summit.points} ${
    summit.activationCount ? '' : 'summit-not-activated'
  } ${selectedCss}`
  const icon = L.divIcon({
    className,
    html: svgTemplate + flagsContainer,
    iconSize: [16, 16],
    iconAnchor: [8, 8],
    popupAnchor: [7, -16],
  })

  // return L.circleMarker([summit.coords.coordinates[1], summit.coords.coordinates[0]],
  //   { radius: getRadius(zoom), className }
  // )
  //   .addEventListener('click', () => onClick(summit))
  //   .bindTooltip(getTooltipText(summit))

  return L.marker([summit.coords.coordinates[1], summit.coords.coordinates[0]], { icon })
    .addEventListener('click', () => onClick(summit))
    .bindTooltip(getTooltipText(summit))
}

export const summitsService = {
  getMarkerForSummit,
}
