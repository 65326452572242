import { GeoPoint } from '../../../common/models/geo.model'

export enum SelectedObjectType {
  POINT = 'POINT',
  SPOT = 'SPOT',
  COUNTRY = 'COUNTRY',
  VOIVODESHIP = 'VOIVODESHIP',
  PARK = 'PARK',
  SUMMIT = 'SUMMIT',
  LOCATOR_GRID = 'LOCATOR_GRID',
}

export type SelectedObject = {
  type: SelectedObjectType
  reference: string
  name: string
  geo?: GeoPoint
}
