/* eslint-disable @typescript-eslint/no-explicit-any */
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import { remove } from 'remove-accents'
// import CircularProgress from '@mui/material/CircularProgress'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDebounce } from '../../../common/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { search } from './search.slice'
import { getSearchResultsSelector, isSearchingSelector } from './search.selector'
import CircularProgress from '@mui/material/CircularProgress'
import { Const } from '../../../common'
import { SearchResult } from './search.model'
import Box from '@mui/material/Box'
import { flagsService } from '../../../services/flags.service'
import { setSelectedObject } from '../aside/aside.slice'
import { SelectedObjectType } from '../aside/aside.model'
import { isLoggedInSelector } from '../../user/user.selectors'
import { getMapBounds } from '../map.selectors'
import { LatLngBounds } from 'leaflet'
import { AppDispatch } from '../../../store'

const KeyboardShortcut = styled.div`
  color: lightgray;
  font-size: 13px;
  width: auto;
  white-space: nowrap;
  margin-left: 4px;
  border: 1px solid lightgray;
  padding: 0 8px;
  border-radius: 4px;
`

const ListItemStyled = styled.li`
  & > ul {
    padding-left: 0;
    margin: 0.5rem 0;
  }
  h5 {
    margin: 0;
    color: #666;
    padding: 0.5rem 0 0.25rem;
    text-align: center;
    background-color: #f0f0f0;
    text-transform: uppercase;
    span {
      font-weight: 100;
      font-size: 0.6rem;
      b {
        font-size: 0.7rem;
      }
    }
  }

  .disabled-item {
    background-color: inherit;
    /* border: 1px solid red; */
    cursor: default;
    text-align: center;
    padding: 16px inherit;
    color: rgba(0, 0, 0, 0.4);
    font-size: 0.75rem;
  }
`

export const SearchComponent = () => {
  const dispatch = useDispatch<AppDispatch>()
  const isLoggedIn = useSelector(isLoggedInSelector)
  const mapBounds: LatLngBounds | undefined = useSelector(getMapBounds)

  const [hasFocus, setHasFocus] = useState(false)
  const [queryTerm, setQueryTerm] = useState('')

  const isSearching = useSelector(isSearchingSelector)
  const searchResults = useSelector(getSearchResultsSelector)
  const queryDebounced = useDebounce(queryTerm, Const.debounceTime)

  useEffect(() => {
    if (!mapBounds) return
    if (!queryDebounced.toString().length) return

    const searchQuery = remove(queryDebounced.toString().trim())
    const searchQueryWithCoordinates = `${searchQuery}&longitude=${mapBounds.getCenter().lng}&latitude=${mapBounds.getCenter().lat}`
    dispatch(search(searchQueryWithCoordinates))
  }, [queryDebounced, dispatch, mapBounds])

  if (!isLoggedIn) return

  let EndAdornment = <></>
  if (!hasFocus) {
    EndAdornment = (
      <>
        <KeyboardShortcut>Ctrl</KeyboardShortcut>
        <KeyboardShortcut>Shift</KeyboardShortcut>
        <KeyboardShortcut>K</KeyboardShortcut>
      </>
    )
  }
  if (isSearching) {
    EndAdornment = <CircularProgress color="inherit" size={20} />
  }

  const handleOnChange = (_: any, value: any) => {
    if (!value) return
    if (value.disabled) return

    const type = (value.type.charAt(0).toUpperCase() + value.type.slice(1)) as SelectedObjectType
    // console.log(value)
    dispatch(
      setSelectedObject({
        reference: value.value,
        name: value.name,
        type,
        geo: JSON.parse(value.geo).coordinates,
      })
    )

    setQueryTerm('')
  }

  const results = searchResults.map((r: SearchResult) => {
    const { key, name, type, country, geo, count } = r
    return count
      ? {
          value: `COUNT_${type}`,
          name: `${type} ${count}`,
          type,
          count,
          country: '--',
          geo: null,
          disabled: true,
        }
      : {
          value: key,
          name,
          type,
          country,
          geo,
          count: 0,
          disabled: false,
        }
  })

  return (
    <>
      <Autocomplete
        id="search-field"
        sx={{ ml: 1, mr: 1, flex: 1, height: '36px' }}
        disablePortal
        autoComplete={false}
        clearIcon={false}
        popupIcon={false}
        blurOnSelect={true}
        clearOnBlur={true}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={handleOnChange}
        // onBlurCapture={}
        onInputChange={(_, value) => setQueryTerm(value)}
        onFocus={() => setHasFocus(true)}
        onBlur={() => {
          setHasFocus(false)
          // console.log('BLUR')
          // dispatch(setSearchTerm(''))
          // setQueryTerm('')
        }}
        noOptionsText="Search for parks, summits, voivodeships by name or reference number"
        renderInput={props => (
          <TextField
            {...props}
            placeholder="Search..."
            fullWidth
            style={{ backgroundColor: 'white', borderRadius: '4px' }}
            InputProps={{
              ...props.InputProps,
              endAdornment: EndAdornment,
            }}
          />
        )}
        options={results}
        getOptionLabel={option => `${option.value} ${option.name}`}
        getOptionDisabled={option => option.disabled}
        renderOption={(props, option) => {
          let flagName = option.country.toLowerCase()
          if (option.type === 'VOIVODESHIP') {
            flagName = flagsService.voivodeshipCountryToFlag(option.country)
          }
          if (option.type === 'SUMMIT') {
            flagName = flagsService.parkOrSummitCountryToFlag(option.country)
          }

          return (
            <Box component="li" sx={{ '& > span': { mr: 2, flexShrink: 0 } }} {...props}>
              <span className={`fi fi-${flagName}`}></span>

              <div>
                <b>{option.value}</b> {option.name}
              </div>
            </Box>
          )
        }}
        groupBy={option => option.type}
        renderGroup={params => {
          const resultsMapped = results
            .map(x => {
              return {
                type: x.type,
                value: x.value,
                name: x.name,
                count: x.count,
              }
            })
            .filter(x => x.type === params.group)
          const currentLength = resultsMapped.length - 1
          const totalLength = resultsMapped.find(x => x.value === `COUNT_${params.group}`)?.count

          return (
            <ListItemStyled key={params.key}>
              <h5>
                {params.group}s
                {currentLength !== totalLength && (
                  <>
                    <br />
                    <span>
                      showing <b>{currentLength}</b> of <b>{totalLength}</b> total results
                    </span>
                  </>
                )}
              </h5>
              <ul>{params.children}</ul>
            </ListItemStyled>
          )
        }}
      ></Autocomplete>
    </>
  )
}
