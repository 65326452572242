import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Flag } from './flags.model'
import { http } from '../../../common/http/http.service'

export interface FlagsState {
  async: boolean
  list: Flag[]
}

const initialState: FlagsState = {
  async: false,
  list: [],
}

export const getFlags = createAsyncThunk('getFlags', async () => {
  const response = await http().get('/flags')
  return response.data
})

export const toggleFlag = createAsyncThunk('toggleFlag', async (dto: Flag) => {
  const response = await http().post('/flags', dto)
  return response.data
})

export const flagsState = createSlice({
  name: 'flags',
  initialState,
  reducers: {
    clearFlags: (state: FlagsState) => {
      state.list = []
    },
  },
  extraReducers: builder => {
    builder.addCase(getFlags.pending, (state: FlagsState) => {
      state.async = true
    })
    builder.addCase(getFlags.fulfilled, (state: FlagsState, action: PayloadAction<Flag[]>) => {
      const { payload } = action

      state.async = false
      state.list = payload
    })
    builder.addCase(getFlags.rejected, (state: FlagsState) => {
      state.async = false
    })

    builder.addCase(toggleFlag.pending, (state: FlagsState) => {
      state.async = true
    })
    builder.addCase(toggleFlag.fulfilled, (state: FlagsState, action: PayloadAction<Flag>) => {
      const { payload } = action
      const { flagged, object, reference, type } = payload

      if (flagged) {
        state.list.push({
          type,
          object,
          reference,
        })
      } else {
        state.list = state.list.filter(x => !(x.type === type && x.object === object && x.reference === reference))
      }

      state.async = false
    })
    builder.addCase(toggleFlag.rejected, (state: FlagsState) => {
      state.async = false
    })
  },
})

export const { clearFlags } = flagsState.actions

export default flagsState.reducer
