import { GeoPoint, LatLngBounds } from '../../common/models/geo.model'

const getCenterPoint = (screenWidthInPixels: number, bounds: LatLngBounds, coordinates: GeoPoint, asideIsOpen: boolean): GeoPoint => {
  const asideWidth = 412
  const w = bounds.getWest()
  const e = bounds.getEast()
  if (asideIsOpen) {
    // console.log(true)
  }

  let spatialWidth = 0
  if (w <= 0 && e <= 0) {
    spatialWidth = Math.abs(w) - Math.abs(e)
  } else if (w <= 0 && e >= 0) {
    spatialWidth = Math.abs(w) + e
  } else if (w >= 0 && e >= 0) {
    spatialWidth = e - w
  } else {
    throw new Error()
  }
  // console.log(spatialWidth)
  const spatialRatio = spatialWidth / screenWidthInPixels
  // console.log(spatialRatio)
  // const lngShift = asideIsOpen ? asideWidth / 2 * spatialRatio : 0
  const lngShift = (asideWidth / 2) * spatialRatio
  // console.log(lngShift)

  return [coordinates[1], coordinates[0] + lngShift]
}

export const mapService = {
  getCenterPoint,
}
