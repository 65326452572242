import styled from 'styled-components'

export const AuthContainer = styled.div`
  margin: 0 auto;
  width: 40rem;

  h1 {
    text-align: center;
  }
`
