/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { AuthConfirmEmail } from '../auth.model'
import { passwordReset } from '../auth.slice'
import { resetPasswordErrorSelector, resetPasswordInProgressSelector, resetPasswordSuccessSelector } from '../auth.selectors'
import { AuthContainer } from '../auth.styles'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import AlertTitle from '@mui/material/AlertTitle'
import { AppDispatch } from '../../../store'
import { changePasswordSchema } from '../auth.schema'

export const AuthResetPasswordPage = () => {
  const { email, confirmationCode } = useParams() as AuthConfirmEmail
  const dispatch = useDispatch<AppDispatch>()

  const inProgress = useSelector(resetPasswordInProgressSelector)
  const isSuccess = useSelector(resetPasswordSuccessSelector)
  const isError = useSelector(resetPasswordErrorSelector)

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(changePasswordSchema),
  })

  const onSubmit = async (payload: any) => {
    try {
      dispatch(
        passwordReset({
          ...payload,
          confirmationCode,
          email,
        })
      )
    } catch (e) {
      console.log('ERR', e)
    }
  }

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        window.location.href = '/'
      }, 3000)
    }
  }, [isSuccess])

  return (
    <AuthContainer>
      <h1>Reset password</h1>

      <p>
        You&apos;re changing the password for <b>{email}</b> account
      </p>
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
        <TextField
          {...register('password')}
          margin="normal"
          required
          fullWidth
          label="New password"
          type="password"
          aria-autocomplete="none"
          autoComplete="off"
          error={errors?.password ? true : false}
          helperText={errors?.password?.message?.toString()}
        />
        <TextField
          {...register('passwordConfirm')}
          margin="normal"
          required
          fullWidth
          label="Retype password"
          type="password"
          aria-autocomplete="none"
          autoComplete="off"
          error={errors?.passwordConfirm ? true : false}
          helperText={errors?.passwordConfirm?.message?.toString()}
        />

        {isSuccess && (
          <Alert severity="success" sx={{ mt: 3 }}>
            <AlertTitle>Well done</AlertTitle>
            Your password has been changed — <a href="/">back to hammap.io</a>
          </Alert>
        )}
        {isError && (
          <Alert severity="error" sx={{ mt: 3 }}>
            <AlertTitle>Something went wrong</AlertTitle>
            There was a problem changing your password, try again later
          </Alert>
        )}
        <Button disabled={!isValid || inProgress} type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
          Change password
        </Button>
      </Box>
    </AuthContainer>
  )
}
