import Paper from '@mui/material/Paper'
import styled from 'styled-components'

export const ModalFormContainer = styled(Paper)`
  /* margin-top: 200px; */
  /* margin-bottom: 200px; */
  width: 400px;
  margin: 100px auto 0;
  padding: 2rem 2rem;
  border-radius: 0.125rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .MuiInputBase-root {
    box-shadow: none;
    border: 1px solid lightgray;
    border-radius: 2px;
  }
  ul {
    margin: 1rem 0 0;
    padding: 0;

    li {
      list-style-type: none;
      padding: 0.25rem 0;

      &:hover {
        cursor: pointer;
      }
    }
  }
`

export const CommentFormContainer = styled(ModalFormContainer)`
  display: flex;
  width: 600px;
  align-items: center;

  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
`
