export enum AuthFormType {
  SIGNIN = 'SignIn',
  SIGNUP = 'SignUp',
  RESET_PASSWORD = 'ResetPassword',
}

export type AuthConfirmEmail = {
  email: string
  confirmationCode: string
}
