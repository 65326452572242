import { Comment } from '../comment/comment.model'
import { InfoChip } from '../../../../../common/components'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import datetimeService from '../../../../../services/datetime.service'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell/TableCell'
import TableRow from '@mui/material/TableRow/TableRow'

export const CommentsListComponent = ({ comments }: { comments: Comment[] | null }) => {
  if (!comments || !comments.length) return

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <b style={{ marginRight: '0.5rem' }}>Comments</b>
        <InfoChip text={comments.length.toString()} />
      </AccordionSummary>
      <AccordionDetails sx={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
        <Table aria-label="table" size="small">
          <TableBody>
            {comments.map((comment: Comment) => {
              return (
                <TableRow key={`${comment.callsign}${comment.timestamp}`}>
                  <TableCell>
                    <b>{comment.callsign}</b> ({datetimeService.timeAgo(comment.timestamp)} ago)
                    <br />
                    {comment.message}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}
