import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { CountriesState } from './countries.slice'

const selectCountriesState = (state: RootState) => state.countries

export const isLoadingSelector = createSelector(selectCountriesState, (state: CountriesState) => state.loading)

export const countriesForBoundsSelector = createSelector(selectCountriesState, (state: CountriesState) => state.countriesForBounds)
export const selectedCountryCommentsSelector = createSelector(selectCountriesState, (state: CountriesState) => state.selectedCountryComments)
