import * as yup from 'yup'

export const addActivationSpotSchema = yup
  .object({
    name: yup.string().max(200).required(),
    comment: yup.string().max(2000),
    facilities: yup.array().of(yup.string()).min(1),
    accessMethods: yup.array().of(yup.string()).min(1),
    activationMethods: yup.array().of(yup.string()).min(1),
  })
  .required()
