/* eslint-disable @typescript-eslint/no-explicit-any */
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Avatar from '@mui/material/Avatar'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { signUpSchema } from '../auth.schema'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { AuthFormType } from '../auth.model'
import { useDispatch, useSelector } from 'react-redux'
import { authComponentChange, signUp } from '../auth.slice'
import { signUpSuccessSelector, signUpErrorSelector, signUpInProgressSelector } from '../auth.selectors'
import { AppDispatch } from '../../../store'

export const SignUpComponent = () => {
  const dispatch = useDispatch<AppDispatch>()
  const inProgress = useSelector(signUpInProgressSelector)
  const signUpSuccess = useSelector(signUpSuccessSelector)
  const signUpError = useSelector(signUpErrorSelector)

  const isProduction = window.location.href.includes('hammap.io')

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(signUpSchema),
  })

  const onCallsingBlur = (e: any) => {
    const { value } = e.target
    if (value) {
      setValue('callsign', value.toUpperCase())
    }
  }

  const onSubmit = async (payload: any) => {
    try {
      dispatch(signUp(payload))
    } catch (e) {
      console.log('ERR', e)
    }
  }

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <h1>Sign Up</h1>
      {!isProduction && (
        <Alert severity="warning">
          <AlertTitle>This is test environment</AlertTitle>
          Go to <a href="https://hammap.io">www.hammap.io</a>
        </Alert>
      )}
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
        {!signUpSuccess && (
          <>
            <TextField
              {...register('email')}
              margin="normal"
              required
              fullWidth
              label="Email"
              type="text"
              aria-autocomplete="none"
              autoComplete="off"
              error={errors?.email ? true : false}
              helperText={errors?.email?.message?.toString()}
            />
            <TextField
              {...register('callsign')}
              margin="normal"
              required
              fullWidth
              label="Callsign"
              type="text"
              aria-autocomplete="none"
              autoComplete="off"
              error={errors?.callsign ? true : false}
              helperText={errors?.callsign?.message?.toString()}
              onBlur={onCallsingBlur}
            />
            <TextField
              {...register('password')}
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              aria-autocomplete="none"
              autoComplete="off"
              error={errors?.password ? true : false}
              helperText={errors?.password?.message?.toString()}
            />
          </>
        )}
        {signUpError && (
          <Alert severity="error">
            <AlertTitle>Ooops</AlertTitle>
            Something went wrong
          </Alert>
        )}
        {!signUpSuccess && (
          <>
            <Button disabled={!isValid || inProgress} type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              Sign Up
            </Button>
            <ul>
              <li>
                <Link underline="always" onClick={() => dispatch(authComponentChange(AuthFormType.SIGNIN))}>
                  Already have account? Sign in
                </Link>
              </li>
            </ul>
          </>
        )}
        {signUpSuccess && (
          <Alert severity="success">
            <AlertTitle>Cool</AlertTitle>
            Your account has been created. Check your mailbox for an activation message. You need to click the link attached to this message.
          </Alert>
        )}
      </Box>
    </>
  )
}
