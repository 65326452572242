import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { VoivodeshipsState } from './voivodeships.slice'

const selectVoivodeshipsState = (state: RootState) => state.voivodeships

export const isLoadingSelector = createSelector(selectVoivodeshipsState, (state: VoivodeshipsState) => state.loading)
export const isLoadingVoivodeshipDetailsSelector = createSelector(selectVoivodeshipsState, (state: VoivodeshipsState) => state.loadingDetails)

export const voivodeshipsForBoundsSelector = createSelector(selectVoivodeshipsState, (state: VoivodeshipsState) => state.voivodeshipsForBounds)

export const selectedVoivodeshipDetailsSelector = createSelector(selectVoivodeshipsState, (state: VoivodeshipsState) => state.selectedVoivodeshipDetails)
export const selectedVoivodeshipCommentsSelector = createSelector(selectVoivodeshipsState, (state: VoivodeshipsState) => state.selectedVoivodeshipComments)
