import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { AuthConfirmEmail } from '../auth.model'
import { confirmEmail } from '../auth.slice'
import { emailConfirmErrorSelector, emailConfirmInProgressSelector, emailConfirmSuccessSelector } from '../auth.selectors'
import { AuthContainer } from '../auth.styles'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import { AppDispatch } from '../../../store'

export const AuthConfirmPage = () => {
  const params = useParams() as AuthConfirmEmail
  const dispatch = useDispatch<AppDispatch>()

  const emailConfirmInProgress = useSelector(emailConfirmInProgressSelector)
  const emailConfirmSuccess = useSelector(emailConfirmSuccessSelector)
  const emailConfirmError = useSelector(emailConfirmErrorSelector)

  useEffect(() => {
    dispatch(confirmEmail(params))
  }, [params, dispatch])

  return (
    <AuthContainer>
      <h1>Email confirm</h1>
      {emailConfirmInProgress && (
        <Alert severity="info">
          <AlertTitle>In progress</AlertTitle>
          Wait for confirmation
        </Alert>
      )}
      {emailConfirmSuccess && (
        <Alert severity="success">
          <AlertTitle>Thank you</AlertTitle>
          Your email has been confirmed — <a href="/">back to app</a>
        </Alert>
      )}
      {emailConfirmError && (
        <Alert severity="error">
          <AlertTitle>Something went wrong</AlertTitle>
          Or your email is already confirmed. Check your mailbox.
        </Alert>
      )}
    </AuthContainer>
  )
}
