import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { LatLngBounds } from '../../common/models/geo.model'
import { SnackBarModel } from './map.model'

export interface MapState {
  loaded: boolean
  zoom: number
  bounds?: LatLngBounds
  snackbar?: SnackBarModel
  activationSpotModal: boolean
  infoModal: boolean
}

const initialState: MapState = {
  loaded: false,
  zoom: localStorage.getItem('coords') ? 10 : 2,
  activationSpotModal: false,
  infoModal: false,
}

export const mapState = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setZoomLevelStore: (state: MapState, { payload }: PayloadAction<number>) => {
      state.zoom = payload
    },
    setBoundsStore: (state: MapState, action: PayloadAction<LatLngBounds>) => {
      const { payload } = action
      state.bounds = payload
    },
    notImplementedSnack: (state: MapState) => {
      state.snackbar = { message: 'Function not implemented yet', duration: 3000 }
    },
    setSnackbar: (state: MapState, { payload }: PayloadAction<string>) => {
      state.snackbar = { message: payload, duration: 3000 }
    },
    clearSnackbar: (state: MapState) => {
      state.snackbar = undefined
    },
    activationSpotModalOpen: (state: MapState) => {
      state.activationSpotModal = true
    },
    activationSpotModalClose: (state: MapState) => {
      state.activationSpotModal = false
    },
    toggleInfoModal: (state: MapState, action: PayloadAction<boolean>) => {
      const { payload } = action
      state.infoModal = payload
    },
  },
})

export const {
  setZoomLevelStore,
  setBoundsStore,
  setSnackbar,
  notImplementedSnack,
  clearSnackbar,
  activationSpotModalOpen,
  activationSpotModalClose,
  toggleInfoModal,
} = mapState.actions

export default mapState.reducer
