/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { NearbySummit, RelatedPark, Summit, SummitActivation, SummitDetails } from './summits.model'
import { LatLngBounds } from '../../../common/models/geo.model'
import { http } from '../../../common/http/http.service'
import { leafletBoundsToString } from '../../../services/utils'
import { Comment } from '../aside/components/comment/comment.model'

export interface SummitsState {
  loading: boolean
  loadingDetails: boolean
  loadingRelatedParks: boolean
  loadingNearbySummits: boolean
  loadingComments: boolean
  summitsForBounds: Summit[]
  selectedSummitDetails: SummitDetails | null
  selectedSummitActivations: SummitActivation[] | null
  selectedSummitRelatedParks: RelatedPark[] | null
  selectedSummitNearbySummits: NearbySummit[] | null
  selectedSummitComments: Comment[] | null
}

const initialState: SummitsState = {
  loading: false,
  loadingDetails: false,
  loadingRelatedParks: false,
  loadingNearbySummits: false,
  loadingComments: false,
  summitsForBounds: [],
  selectedSummitDetails: null,
  selectedSummitActivations: null,
  selectedSummitRelatedParks: null,
  selectedSummitNearbySummits: null,
  selectedSummitComments: null,
}

export const getSummitsForBounds = createAsyncThunk('getSummitsForBounds', async (bounds: LatLngBounds) => {
  const response = await http().get(`/summits?${leafletBoundsToString(bounds)}`)
  return response.data
})

export const getSummitDetails = createAsyncThunk('getSummitDetails', async (reference: string) => {
  const response = await http().get(`/summits/${reference}`)
  return response.data
})

export const getRelatedParks = createAsyncThunk('getRelatedParks', async (reference: string) => {
  const response = await http().get(`/summits/${reference}/parks`)
  return response.data
})

export const getSummitsNearby = createAsyncThunk('getSummitsNearby', async (reference: string) => {
  const response = await http().get(`/summits/${reference}/nearby`)
  return response.data
})

export const getSummitsComments = createAsyncThunk('getSummitsComments', async (reference: string) => {
  const response = await http().get(`/summits/${reference}/comments`)
  return response.data
})

export const summitsState = createSlice({
  name: 'summits',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getSummitsForBounds.pending, (state: SummitsState) => {
      state.loading = true
    })
    builder.addCase(getSummitsForBounds.fulfilled, (state: SummitsState, action: PayloadAction<any>) => {
      const { payload } = action

      state.loading = false
      state.summitsForBounds = payload
    })
    builder.addCase(getSummitsForBounds.rejected, (state: SummitsState) => {
      state.loading = false
    })
    builder.addCase(getSummitDetails.pending, (state: SummitsState) => {
      state.loadingDetails = true
    })
    builder.addCase(getSummitDetails.fulfilled, (state: SummitsState, action: PayloadAction<SummitDetails>) => {
      const { payload } = action

      state.loadingDetails = false
      state.selectedSummitDetails = { ...payload, metadata: null }
      state.selectedSummitActivations = payload.metadata?.activations
    })
    builder.addCase(getSummitDetails.rejected, (state: SummitsState) => {
      state.loadingDetails = false
    })
    builder.addCase(getRelatedParks.pending, (state: SummitsState) => {
      state.loadingRelatedParks = true
    })
    builder.addCase(getRelatedParks.fulfilled, (state: SummitsState, action: PayloadAction<RelatedPark[]>) => {
      const { payload } = action

      state.loadingRelatedParks = false
      state.selectedSummitRelatedParks = payload
    })
    builder.addCase(getRelatedParks.rejected, (state: SummitsState) => {
      state.loadingRelatedParks = false
    })
    builder.addCase(getSummitsNearby.pending, (state: SummitsState) => {
      state.loadingNearbySummits = true
    })
    builder.addCase(getSummitsNearby.fulfilled, (state: SummitsState, action: PayloadAction<NearbySummit[]>) => {
      const { payload } = action

      state.loadingNearbySummits = false
      state.selectedSummitNearbySummits = payload
    })
    builder.addCase(getSummitsNearby.rejected, (state: SummitsState) => {
      state.loadingNearbySummits = false
    })
    builder.addCase(getSummitsComments.pending, (state: SummitsState) => {
      state.loadingComments = true
    })
    builder.addCase(getSummitsComments.fulfilled, (state: SummitsState, action: PayloadAction<any[]>) => {
      const { payload } = action

      state.loadingComments = false
      state.selectedSummitComments = payload
    })
    builder.addCase(getSummitsComments.rejected, (state: SummitsState) => {
      state.loadingComments = false
    })
  },
})

// export const { setSelectedParkStore, clearSelecteParkStore } = parksState.actions

export default summitsState.reducer
