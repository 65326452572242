const voivodeshipCountryMappings = [
  ['9M', 'my'],
  ['AD', 'ad'],
  ['AE', 'ae'],
  ['AF', 'af'],
  // "AI"
  // "AL"
  // "AO"
  // "AQ"
  // "AR"
  // "AT"
  // "AU"
  // "AW"
  // "AZ"
  // "BA"
  // "BB"
  // "BD"
  // "BE"
  // "BF"
  // "BG"
  // "BH"
  // "BI"
  // "BJ"
  // "BL"
  // "BM"
  // "BN"
  // "BO"
  // "BR"
  // "BS"
  // "BT"
  // "BV"
  // "BW"
  // "BY"
  // "BZ"
  // "C"
  // "CA"
  // "CF"
  // "CL"
  // "CM"
  // "CN"
  // "CO"
  // "CR"
  // "CU"
  // "CV"
  // "CY"
  // "CZ"
  // "DE"
  // "DJ"
  // "DK"
  // "DM"
  // "DO"
  // "DZ"
  // "EA"
  // "EC"
  // "EE"
  // "EG"
  // "EH"
  // "EK"
  // "ER"
  // "ET"
  // "F"
  // "FI"
  // "FJ"
  // "FM"
  // "FO"
  // "FR"
  // "G"
  // "GA"
  // "GB"
  // "GD"
  // "GE"
  // "GF"
  // "GG"
  // "GH"
  // "GL"
  // "GM"
  // "GN"
  // "GP"
  // "GQ"
  // "GR"
  // "GT"
  // "GW"
  // "GY"
  // "HB"
  // "HK"
  // "HN"
  // "HR"
  // "HT"
  // "HU"
  // "ID"
  // "IE"
  // "IL"
  // "IM"
  // "IN"
  // "IQ"
  // "IR"
  // "IS"
  // "IT"
  // "JE"
  // "JM"
  // "JO"
  // "JP"
  // "KE"
  // "KG"
  // "KH"
  // "KM"
  // "KN"
  // "KP"
  // "KR"
  // "KW"
  // "KZ"
  // "LA"
  // "LB"
  // "LC"
  // "LI"
  // "LK"
  // "LR"
  // "LS"
  // "LT"
  // "LU"
  // "LV"
  // "LY"
  // "MA"
  // "MD"
  // "ME"
  // "MG"
  // "MH"
  // "MK"
  // "ML"
  // "MM"
  // "MN"
  // "MP"
  // "MR"
  // "MS"
  // "MT"
  // "MV"
  // "MZ"
  // "N"
  // "NA"
  // "NE"
  // "NG"
  // "NI"
  // "NL"
  // "NO"
  // "NP"
  // "NR"
  // "NU"
  // "NZ"
  // "OA"
  // "OM"
  // "PA"
  // "PG"
  // "PH"
  // "PK"
  // "PL"
  // "PS"
  // "PT"
  // "PW"
  // "PY"
  // "QA"
  // "RO"
  // "RS"
  // "RU"
  // "RW"
  // "S"
  // "SA"
  // "SB"
  // "SC"
  // "SD"
  // "SE"
  // "SG"
  // "SI"
  // "SK"
  // "SL"
  // "SM"
  // "SN"
  // "SO"
  // "SR"
  // "SV"
  // "SX"
  // "SY"
  // "SZ"
  // "TC"
  // "TD"
  // "TG"
  // "TH"
  // "TI"
  // "TJ"
  // "TL"
  // "TM"
  // "TN"
  // "TO"
  // "TR"
  // "TT"
  // "TV"
  // "TZ"
  // "UA"
  // "US"
  // "UY"
  // "UZ"
  // "VA"
  // "VC"
  // "VE"
  // "VI"
  // "VN"
  // "VU"
  // "WS"
  // "XE"
  // "YB"
  // "YE"
  // "ZA"
  // "ZF"
  // "ZL"
  // "ZM"
  // "ZW"
]

const parkOrSummitCountryMappings = [
  ['3D2', 'fj'],
  ['3Y', 'bv'],
  ['4O', 'cy'],
  ['4W', 'tl'],
  ['4X', 'il'],
  ['5B', 'cy'],
  ['6Y', 'jm'],
  ['8P', 'bb'],
  ['8R', 'gy'],
  ['9A', 'hr'],
  ['9H', 'mt'],
  ['9G1', 'gh'],
  ['9M', 'my'],
  ['9M2', 'my'],
  ['9M6', 'my'],
  ['9V', 'sg'],
  ['9Y', 'tt'],
  ['A2', 'bw'],
  ['A3', 'to'],
  ['A6', 'ae'],
  ['AR', 'ar'],
  ['BV', 'tw'],
  ['BY', 'cn'],
  ['C3', 'ad'],
  ['C6', 'bs'],
  ['C9', 'mz'],
  ['CA', 'cl'],
  ['CE3', 'cl'],
  ['CE9', 'aq'],
  ['CO', 'cu'],
  ['CP', 'bo'],
  ['CT', 'pt'],
  ['CT3', ''],
  ['CU', 'fo'],
  ['CX', 'ug'],
  ['DA', 'de'],
  ['DL', 'de'],
  ['DM', 'de'],
  ['DU', 'ph'],
  ['DU2', 'ph'],
  ['DU3', 'ph'],
  ['DU4', 'ph'],
  ['DUC', 'ph'],
  ['E5', 'ck'],
  ['E7', 'ba'],
  ['EA', 'es'],
  ['EA1', 'es'],
  ['EA2', 'es'],
  ['EA3', 'es'],
  ['EA4', 'es'],
  ['EA5', 'es'],
  ['EA6', 'es'],
  ['EA7', 'es'],
  ['EA8', 'ic'],
  ['EA9', 'es'],
  ['EI', 'ie'],
  ['EK', 'am'],
  ['F', 'fr'],
  ['FG', 'gp'],
  ['FH', 'yt'],
  ['FK', 'nc'],
  ['FL', 'fr'],
  ['FM', 'mq'],
  ['FP', 'pm'],
  ['FR', 're'],
  ['G', 'gb-eng'],
  ['GD', 'im'],
  ['GI', 'gb-nir'],
  ['GJ', 'je'],
  ['GM', 'gb-sct'],
  ['GU', 'gg'],
  ['GW', 'gb-wls'],
  ['HA', 'hu'],
  ['HB', 'ch'],
  ['HB0', 'li'],
  ['HC', 'ec'],
  ['HI', 'do'],
  ['HK', 'co'],
  ['HL', 'kr'],
  ['HP', 'pa'],
  ['HR', 'hn'],
  ['HS', 'th'],
  ['I', 'it'],
  ['IA', 'it'],
  ['IS0', 'fr'],
  ['J3', 'gd'],
  ['J6', 'lc'],
  ['J7', 'dm'],
  ['J8', 'vc'],
  ['JA', 'ja'],
  ['JA5', 'ja'],
  ['JA6', 'ja'],
  ['JA8', 'ja'],
  ['JW', 'sj'],
  ['JX', 'sj'],
  ['K', 'us'],
  ['K0M', 'us'],
  ['KH0', 'us'],
  ['KH2', 'us'],
  ['KH6', 'us'],
  ['KLA', 'us'],
  ['KLF', 'us'],
  ['KLS', 'us'],
  ['KP4', 'us'],
  ['LA', 'no'],
  ['LUD', 'ar'],
  ['LUH', 'ar'],
  ['LUI', 'ar'],
  ['LUK', 'ar'],
  ['LUM', 'ar'],
  ['LUN', 'ar'],
  ['LUP', 'ar'],
  ['LUQ', 'ar'],
  ['LUU', 'ar'],
  ['LUV', 'ar'],
  ['LUY', 'ar'],
  ['LX', 'lu'],
  ['LY', 'lt'],
  ['LZ', 'bg'],
  ['OA', 'pe'],
  ['OD', 'lb'],
  ['OE', 'at'],
  ['OH', 'fi'],
  ['OK', 'cz'],
  ['OM', 'sk'],
  ['ON', 'be'],
  ['OU', 'dk'],
  ['OX', 'gl'],
  ['OY', 'fo'],
  ['OZ', 'dk'],
  ['P4', 'aw'],
  ['PA', 'nl'],
  ['P29', 'pg'],
  ['PP1', 'br'],
  ['PP2', 'br'],
  ['PP5', 'br'],
  ['PP6', 'br'],
  ['PP7', 'br'],
  ['PQ2', 'br'],
  ['PR8', 'br'],
  ['PS7', 'br'],
  ['PS8', 'br'],
  ['PT2', 'br'],
  ['PT7', 'br'],
  ['PY', 'br'],
  ['PY1', 'br'],
  ['PY2', 'br'],
  ['PY3', 'br'],
  ['PY4', 'br'],
  ['PY5', 'br'],
  ['PY6', 'br'],
  ['PYF', 'br'],
  ['PYT', 'br'],
  ['PZ', 'sr'],
  ['R3', 'ru'],
  ['R9U', 'ru'],
  ['S5', 'si'],
  ['S7', 'sc'],
  ['SM', 'se'],
  ['SP', 'pl'],
  ['SV', 'gr'],
  ['T7', 'sm'],
  ['T9', 'ba'],
  ['TA', 'tr'],
  ['TF', 'ic'],
  ['TG', 'gt'],
  ['TI', 'cr'],
  ['TK', 'fr'],
  ['UA', 'ru'],
  ['UT', 'ua'],
  ['UR', 'ua'],
  ['V3', 'bz'],
  ['V4', 'kn'],
  ['V5', 'na'],
  ['V6', 'fm'],
  ['V7', 'mh'],
  ['VE', 'ca'],
  ['VE1', 'ca'],
  ['VE2', 'ca'],
  ['VE3', 'ca'],
  ['VE4', 'ca'],
  ['VE5', 'ca'],
  ['VE6', 'ca'],
  ['VE7', 'ca'],
  ['VE8', 'ca'],
  ['VE9', 'ca'],
  ['VK', 'au'],
  ['VK1', 'au'],
  ['VK2', 'au'],
  ['VK3', 'au'],
  ['VK4', 'au'],
  ['VK5', 'au'],
  ['VK6', 'au'],
  ['VK7', 'au'],
  ['VK8', 'au'],
  ['VK9', 'au'],
  ['VKH', 'hm'],
  ['VKM', ''], // TODO: Macquaire island
  ['VP5', 'tc'],
  ['VO1', 'ca'],
  ['VO2', 'ca'],
  ['VU', 'in'],
  ['VP8', 'fk'],
  ['VR', 'hk'],
  ['VY1', 'ca'],
  ['VY2', 'ca'],
  ['W0C', 'us'],
  ['W0D', 'us'],
  ['W0I', 'us'],
  ['W0M', 'us'],
  ['W0N', 'us'],
  ['W1', 'us'],
  ['W2', 'us'],
  ['W3', 'us'],
  ['W4A', 'us'],
  ['W4C', 'us'],
  ['W4G', 'us'],
  ['W4K', 'us'],
  ['W4T', 'us'],
  ['W4V', 'us'],
  ['W5A', 'us'],
  ['W5M', 'us'],
  ['W5N', 'us'],
  ['W5O', 'us'],
  ['W5T', 'us'],
  ['W6', 'us'],
  ['W7A', 'us'],
  ['W7I', 'us'],
  ['W7M', 'us'],
  ['W7N', 'us'],
  ['W7O', 'us'],
  ['W7U', 'us'],
  ['W7W', 'us'],
  ['W7Y', 'us'],
  ['W8M', 'us'],
  ['W8O', 'us'],
  ['W8V', 'us'],
  ['W9', 'us'],
  ['XE', 'mx'],
  ['XE1', 'mx'],
  ['XE2', 'mx'],
  ['XE3', 'mx'],
  ['XE4', 'mx'],
  ['XV2', 'vn'],
  ['YB', 'id'],
  ['YBE', 'id'],
  ['YBJ', 'id'],
  ['YJ8', 'vu'],
  ['YL', 'lv'],
  ['YO', 'ro'],
  ['YS', 'sv'],
  ['YT', 'rs'],
  ['YV', 've'],
  ['YU', 'rs'],
  ['Z2', 'zw'],
  ['Z3', 'mk'],
  ['ZB2', 'gi'],
  ['ZD', 'gb'],
  ['ZF', 'ky'],
  ['ZL', 'nz'],
  ['ZL1', 'nz'],
  ['ZL2', 'nz'],
  ['ZL7', 'nz'],
  ['ZL8', 'nz'],
  ['ZL9', 'nz'],
  ['ZP', 'py'],
  ['ZR', 'za'],
  ['ZS', 'za'],
  ['ZS8', 'za'],
]

const voivodeshipCountryToFlag = (country: string): string => {
  const flag = voivodeshipCountryMappings.find(mapping => mapping[0] === country)
  return flag ? flag[1] : country.toLowerCase()
}

const parkOrSummitCountryToFlag = (country: string): string => {
  const flag = parkOrSummitCountryMappings.find(mapping => mapping[0] === country)
  return flag ? flag[1] : 'xx'
}

export const flagsService = {
  voivodeshipCountryToFlag,
  parkOrSummitCountryToFlag,
}
