import Chip from '@mui/material/Chip'
import styled from 'styled-components'

const ChipStyled = styled(Chip)`
  border-radius: 4px !important;
  font-weight: 600;
`

export const LoadingChip = () => <ChipStyled data-testid="loading-chip" color="primary" variant="filled" size="small" label="Loading..." />

export const DefaultChip = ({ text }: { text: string }) => <ChipStyled data-testid="default-chip" color="primary" variant="filled" size="small" label={text} />

export const InfoChip = ({ text }: { text: string }) => <ChipStyled data-testid="info-chip" color="default" variant="filled" size="small" label={text} />
