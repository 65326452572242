import { useSelector } from 'react-redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { SummitDetails } from '../../../summits/summits.model'
import { selectedSummitDetailsSelector } from '../../../summits/summits.selectors'
import { InfoChip } from '../../../../../common/components'

export const SummitDetailsComponent = () => {
  const selectedSummitDetails: SummitDetails | null = useSelector(selectedSummitDetailsSelector)
  if (!selectedSummitDetails) return

  const { name, coords, altM, altFt, points, activationCount } = selectedSummitDetails

  return (
    <div style={{ marginBottom: 16 }}>
      <Table aria-label="table">
        <TableBody>
          <TableRow>
            <TableCell align="left">Name</TableCell>
            <TableCell align="right">
              <b>{name}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Geo coordinates</TableCell>
            <TableCell align="right">
              <b>{coords.coordinates.map(x => x.toFixed(5)).join(', ')}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Locator grid(s)</TableCell>
            <TableCell align="right">
              <b></b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Altitude</TableCell>
            <TableCell align="right">
              <b>
                {altM} m / {altFt} ft
              </b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Points</TableCell>
            <TableCell align="right">
              <InfoChip text={points.toString()} />
            </TableCell>
          </TableRow>
          {activationCount === 0 && (
            <TableRow>
              <TableCell align="left">Activations</TableCell>
              <TableCell align="right">{activationCount === 0 && <InfoChip text="Never activated" />}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}
