/* eslint-disable @typescript-eslint/no-explicit-any */
import L from 'leaflet'
import { SelectedObject, SelectedObjectType } from '../aside/aside.model'
import { Voivodeship } from './voivodeships.model'
import { Flag, FlagType } from '../flags/flags.model'

const getAreaForVoivodeship = (voivodeship: Voivodeship, zoom: number, selectedObject: SelectedObject | null | undefined, flags: Flag[], onClick: any) => {
  const getAreaFillColor = () => {
    if (selectedObject?.type === SelectedObjectType.VOIVODESHIP && voivodeship.potaAbbreviation === selectedObject?.reference) {
      return 'blue'
    }
    if (flags.some(f => f.reference === voivodeship.potaAbbreviation && f.object === SelectedObjectType.VOIVODESHIP && f.type === FlagType.BOOKMARK)) {
      return 'orange'
    }
    if (flags.some(f => f.reference === voivodeship.potaAbbreviation && f.object === SelectedObjectType.VOIVODESHIP && f.type === FlagType.ACTIVATED)) {
      return 'green'
    }
    if (flags.some(f => f.reference === voivodeship.potaAbbreviation && f.object === SelectedObjectType.VOIVODESHIP && f.type === FlagType.HUNTED)) {
      return 'deep-purple'
    }
    return 'gray'
  }

  const geoJsonFeature: any = {
    type: 'Feature',
    properties: {
      name: voivodeship.potaAbbreviation,
    },
    geometry: voivodeship.geo,
  }

  const getDetails = () =>
    zoom === 7
      ? `
    <p>
      Parks <span class="activated">${voivodeship.parksCount}</span>
      <span class="not-activated">${voivodeship.parksNotActivatedCount}</span>
    </p>
    `
      : `
    <p>
      Total parks <span class="activated">${voivodeship.parksCount}</span>
      never activated <span class="not-activated">${voivodeship.parksNotActivatedCount}</span>
    </p>
    `
  // activated by you <span class="activated-by-you">${Math.trunc(Math.random() * 100)}</span>

  const geoJSONStyles = () => {
    //   const fillColor = park.activations ? 'lightseagreen' : 'gray'

    return {
      fillColor: getAreaFillColor(),
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.2,
    }
  }

  return L.geoJSON(geoJsonFeature, { style: geoJSONStyles() })
    .addEventListener('click', () => onClick(voivodeship))
    .bindTooltip(
      `<div>
      <h1>${voivodeship.potaAbbreviation}</h1>
      <h2>${voivodeship.name}</h2>
      ${getDetails()}
    </div>`,
      { permanent: true, direction: 'center', className: 'voivodeship-label' }
    )
}

export const voivodeshipService = {
  getAreaForVoivodeship,
}
