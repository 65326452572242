/* eslint-disable @typescript-eslint/no-explicit-any */
import { MapContainer, Polygon, TileLayer } from 'react-leaflet'
import { useEffect, useState } from 'react'
import { MapContainerStyles } from '../../map/Map.styles'
import { replace } from '../../../services/utils'
import { LatLngExpression } from 'leaflet'
import { http } from '../../../common/http/http.service'
import { ApiUrl } from '../../../common/services/config.service'
import { VoivodeshipsList } from './VoivodeshipsList'
import { MatchButton } from '../countries/Match'
import { LoadFile } from './LoadFile'

export const VoivodeshipsPage = () => {
  const [mapCenter] = useState<LatLngExpression>([52.4, 20.9])
  const [selectedArea, setSelectedArea] = useState<any>()
  const [selectedAreaFeatureId, setSelectedAreaFeatureId] = useState<number>(0)
  const [selectedVoivodeship, setSelectedVoivodeship] = useState<any>()
  const [listOfVoivodeships, setListOfVoivodeships] = useState<any>()

  useEffect(() => {
    loadVoivodeships()
  }, [])

  const loadVoivodeships = async () => {
    const { data } = await http().get(`${ApiUrl()}/voivodeships/list/all`)
    setListOfVoivodeships(data)
  }

  const onVoivodeshipClick = (voivodeship: any) => {
    if (voivodeship.potaAbbreviation === selectedVoivodeship?.potaAbbreviation) {
      setSelectedVoivodeship(null)
      return
    }

    setSelectedVoivodeship(voivodeship)
  }

  const onVoivodeshipFeatureClickHandle = (id: number) => setSelectedAreaFeatureId(id)

  const onLoadHandle = async (filePath: string) => {
    const file = await fetch('/vo/' + filePath)
    const response = await file.json()

    setSelectedArea(response)
  }

  const getVoivodeshipsFromLoadedFile = () => {
    if (!selectedArea) return null
    return selectedArea.features.map((item: any, index: number) => {
      return {
        id: index,
        name: item.properties['NAME_1'],
      }
    })
  }

  const onMatchClick = async () => {
    const metadata = {
      fileName: selectedArea.name,
      crs: selectedArea.crs,
      featuresCount: selectedArea.features.length,
      featureId: selectedAreaFeatureId,
      featureProperties: selectedArea.features[0].properties,
      timestamp: new Date(),
    }

    const input = { potaAbbreviation: selectedVoivodeship.potaAbbreviation, geo: selectedArea.features[selectedAreaFeatureId].geometry, metadata }
    await http().post(`${ApiUrl()}/voivodeships/area`, input)
    setSelectedVoivodeship(null)
    loadVoivodeships()
  }

  return (
    <div>
      <VoivodeshipsList voivodeships={listOfVoivodeships} onVoivodeshipClick={onVoivodeshipClick} selectedVoivodeship={selectedVoivodeship} />
      <LoadFile
        onLoad={onLoadHandle}
        voivodeships={getVoivodeshipsFromLoadedFile()}
        onVoivodeshipClick={onVoivodeshipFeatureClickHandle}
        selectedVoivodeship={selectedAreaFeatureId}
      />
      {selectedArea && selectedVoivodeship && <MatchButton matchClick={onMatchClick} />}
      <MapContainerStyles>
        <MapContainer center={mapCenter} zoom={6} scrollWheelZoom={true}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {selectedArea && (
            <Polygon
              pathOptions={{ fillColor: 'yellow', color: 'red' }}
              positions={replace(selectedArea.features[selectedAreaFeatureId].geometry.coordinates)}
            />
          )}
        </MapContainer>
      </MapContainerStyles>
    </div>
  )
}

// http://localhost:3004/vo/gadm41_POL_1.json
