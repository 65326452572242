/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  z-index: 5000;
  left: 50px;
  top: 45%;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 16px;

  button {
    font-size: 18px;
    padding: 16px;
  }
`

export const MatchButton = ({ matchClick }: any) => {

  return (
    <Container>
      <button onClick={matchClick}>MATCH</button>
    </Container>
  )
}
