/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import BookmarkTwoToneIcon from '@mui/icons-material/BookmarkTwoTone'
import OutboundIcon from '@mui/icons-material/Outbound'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone'
import ShieldTwoToneIcon from '@mui/icons-material/ShieldTwoTone'
import { FlagType } from '../../flags/flags.model'
import { useSelector } from 'react-redux'
import { getFlagsSelector } from '../../flags/flags.selectors'

const Container = styled.div`
  display: flex;
  justify-content: center;
`

export const IconsComponent = ({ reference, objectType, onToggleClick }: any) => {
  const flags = useSelector(getFlagsSelector)
  const isBookmarked = flags.some(x => x.reference === reference && x.object === objectType && x.type === FlagType.BOOKMARK)
  const isActivated = flags.some(x => x.reference === reference && x.object === objectType && x.type === FlagType.ACTIVATED)
  const isHunted = flags.some(x => x.reference === reference && x.object === objectType && x.type === FlagType.HUNTED)

  return (
    <Container>
      {isBookmarked ? (
        <Tooltip title="Click to unbookmark">
          <IconButton aria-label="Bookmarked" size="large" color="warning" onClick={() => onToggleClick(FlagType.BOOKMARK)}>
            <BookmarkIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Bookmark">
          <IconButton aria-label="Bookmark" size="large" color="default" onClick={() => onToggleClick(FlagType.BOOKMARK)}>
            <BookmarkTwoToneIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      )}
      {isActivated ? (
        <Tooltip title="Click to unmark activated">
          <IconButton aria-label="Activated" size="large" color="success" onClick={() => onToggleClick(FlagType.ACTIVATED)}>
            <OutboundIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Mark as activated">
          <IconButton aria-label="Activated" size="large" color="default" onClick={() => onToggleClick(FlagType.ACTIVATED)}>
            <CircleTwoToneIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      )}
      {isHunted ? (
        <Tooltip title="Click to unmark hunted">
          <IconButton aria-label="Hunted" size="large" color="secondary" onClick={() => onToggleClick(FlagType.HUNTED)}>
            <VerifiedUserIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Mark as hunted">
          <IconButton aria-label="Mark as hunted" size="large" color="default" onClick={() => onToggleClick(FlagType.HUNTED)}>
            <ShieldTwoToneIcon fontSize="medium" />
          </IconButton>
        </Tooltip>
      )}
    </Container>
  )
}
