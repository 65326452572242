import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { AuthState } from './auth.slice'

const selectAuthState = (state: RootState) => state.auth

export const isModalOpenSelector = createSelector(selectAuthState, (state: AuthState) => state.isModalOpen)
export const authComponentSelector = createSelector(selectAuthState, (state: AuthState) => state.authComponent)
export const getTokenSelector = createSelector(selectAuthState, (state: AuthState) => state.token)

export const signUpInProgressSelector = createSelector(selectAuthState, (state: AuthState) => state.signUpInProgress)
export const signUpSuccessSelector = createSelector(selectAuthState, (state: AuthState) => state.signUpSuccess)
export const signUpErrorSelector = createSelector(selectAuthState, (state: AuthState) => state.signUpError)

export const emailConfirmInProgressSelector = createSelector(selectAuthState, (state: AuthState) => state.emailConfirmInProgress)
export const emailConfirmSuccessSelector = createSelector(selectAuthState, (state: AuthState) => state.emailConfirmSuccess)
export const emailConfirmErrorSelector = createSelector(selectAuthState, (state: AuthState) => state.emailConfirmError)

export const signInInProgressSelector = createSelector(selectAuthState, (state: AuthState) => state.signInInProgress)
export const signInSuccessSelector = createSelector(selectAuthState, (state: AuthState) => state.signInSuccess)
export const signInErrorSelector = createSelector(selectAuthState, (state: AuthState) => state.signInError)

export const resetPasswordRequestInProgressSelector = createSelector(selectAuthState, (state: AuthState) => state.resetPasswordRequestInProgress)
export const resetPasswordRequestSuccessSelector = createSelector(selectAuthState, (state: AuthState) => state.resetPasswordRequestSuccess)
export const resetPasswordRequestErrorSelector = createSelector(selectAuthState, (state: AuthState) => state.resetPasswordRequestError)

export const resetPasswordInProgressSelector = createSelector(selectAuthState, (state: AuthState) => state.resetPasswordInProgress)
export const resetPasswordSuccessSelector = createSelector(selectAuthState, (state: AuthState) => state.resetPasswordSuccess)
export const resetPasswordErrorSelector = createSelector(selectAuthState, (state: AuthState) => state.resetPasswordError)
