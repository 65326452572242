import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useSelector } from 'react-redux'
import { getSelectedObject } from '../../aside.selectors'
import { PointResultsComponent } from './PointResults.component'

export const PointDetailsComponent = () => {
  const selectedObject = useSelector(getSelectedObject)

  return (
    <>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-expanded>
          <b style={{ marginRight: '0.5rem' }}>Point coordinates</b>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
          <Table aria-label="table">
            <TableBody>
              <TableRow>
                <TableCell align="left">Latitude</TableCell>
                <TableCell align="right">
                  <b>{selectedObject?.geo?.[1].toFixed(4)}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Longitude</TableCell>
                <TableCell align="right">
                  <b>{selectedObject?.geo?.[0].toFixed(4)}</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Locator grid</TableCell>
                <TableCell align="right">
                  <b></b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>

      <PointResultsComponent />
    </>
  )
}
