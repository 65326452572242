import { useDispatch, useSelector } from 'react-redux'
import { selectedSummitNearbySummitsSelector } from '../../../summits/summits.selectors'
import { NearbySummit } from '../../../summits/summits.model'
import { setSelectedObject } from '../../aside.slice'
import { SelectedObjectType } from '../../aside.model'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import { InfoChip } from '../../../../../common/components/Badge'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccordionDetails from '@mui/material/AccordionDetails'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import styled from 'styled-components'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

const TableRowStyled = styled(TableRow)`
  &:hover {
    background-color: #ececec;
    cursor: pointer;
  }
`

export const SummitNearbySummitsComponent = () => {
  const dispatch = useDispatch()
  const summits = useSelector(selectedSummitNearbySummitsSelector)
  if (!summits) return

  const handleRowClick = (relation: NearbySummit) => {
    dispatch(
      setSelectedObject({
        reference: relation.summitCode,
        name: relation.name,
        type: SelectedObjectType.SUMMIT,
        geo: relation.coords.coordinates,
      })
    )
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <b style={{ marginRight: '0.5rem' }}>Summits nearby</b>
        <InfoChip text={summits.length.toString()} />
      </AccordionSummary>
      <AccordionDetails sx={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
        <Table aria-label="table" size="small">
          <TableBody>
            {summits.map((summit: NearbySummit) => {
              return (
                <TableRowStyled key={summit.summitCode} onClick={() => handleRowClick(summit)}>
                  <TableCell align="left">
                    <b>{summit.summitCode}</b> {summit.name}
                  </TableCell>
                  <TableCell align="right">{(summit.distance / 1000).toFixed(2)} km</TableCell>
                </TableRowStyled>
              )
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}
