import Modal from '@mui/material/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { asyncSelector, isCommentModalOpenSelector } from './comment.selectors'
import { addComment, commentModalClose } from './comment.slice'
import { CommentFormContainer } from '../../../../../common/layout/Modal.styles'
import Avatar from '@mui/material/Avatar'
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded'
import Box from '@mui/material/Box'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { addCommentSchema } from './comment.schema'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { AddCommentRequest, CommentFormModel } from './comment.model'
import { getSelectedObject } from '../../aside.selectors'
import { AppDispatch } from '../../../../../store'
import { useEffect, useState } from 'react'
import { SelectedObjectType } from '../../aside.model'
import { getParksComments } from '../../../parks/parks.slice'
import { getSummitsComments } from '../../../summits/summits.slice'
import { getVoivodeshipsComments } from '../../../voivodeships/voivodeships.slice'
import { getCountryComments } from '../../../countries/countries.slice'

export const CommentModalComponent = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [submitted, setSubmitted] = useState(false)
  const isOpen = useSelector(isCommentModalOpenSelector)
  const async = useSelector(asyncSelector)
  const selectedObject = useSelector(getSelectedObject)

  useEffect(() => {
    // TODO: yeah, I know...
    if (submitted === true && async === false && selectedObject) {
      if (selectedObject.type === SelectedObjectType.PARK) {
        dispatch(getParksComments(selectedObject.reference))
      }
      if (selectedObject.type === SelectedObjectType.SUMMIT) {
        dispatch(getSummitsComments(selectedObject.reference))
      }
      if (selectedObject.type === SelectedObjectType.VOIVODESHIP) {
        dispatch(getVoivodeshipsComments(selectedObject.reference))
      }
      if (selectedObject.type === SelectedObjectType.COUNTRY) {
        dispatch(getCountryComments(selectedObject.reference))
      }
    }
  }, [submitted, async, selectedObject, dispatch])

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(addCommentSchema),
  })

  const onSubmit = async (payload: CommentFormModel) => {
    try {
      const { type, reference } = selectedObject!
      const comment: AddCommentRequest = {
        message: payload.comment,
        reference,
        type,
      }

      dispatch(addComment(comment))
      setSubmitted(true)
      reset()
    } catch (e) {
      // console.log('ERR', e)
    }
  }

  const handleClose = () => {
    reset()
    dispatch(commentModalClose())
  }

  if (!isOpen) return

  return (
    <>
      <Modal keepMounted open={isOpen} onClose={handleClose} style={{ paddingLeft: '412px', height: '100%' }}>
        <CommentFormContainer elevation={3}>
          {/* <Component /> */}
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <ChatBubbleRoundedIcon />
          </Avatar>
          <h1>Add a comment</h1>
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} onReset={handleClose} sx={{ mt: 1, width: '100%' }}>
            <TextField
              {...register('comment')}
              margin="normal"
              required
              multiline={true}
              rows={5}
              fullWidth
              placeholder="Max 2000 characters"
              type="text"
              aria-autocomplete="none"
              autoComplete="off"
              error={errors?.comment ? true : false}
              helperText={errors?.comment?.message?.toString()}
            />
            <Button type="reset" variant="contained" color="inherit" sx={{ mt: 3 }}>
              Cancel
            </Button>
            <Button disabled={!isValid || async} type="submit" variant="contained" sx={{ mt: 3, ml: 2 }}>
              Add
            </Button>
          </Box>
        </CommentFormContainer>
      </Modal>
    </>
  )
}
