import { useDispatch, useSelector } from 'react-redux'
import { selectedParkDetailsSelector } from '../../../parks/parks.selectors'
import { ParkDetails } from '../../../parks/parks.model'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { InfoChip } from '../../../../../common/components'
import Button from '@mui/material/Button'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { notImplementedSnack } from '../../../map.slice'

export const ParkDetailsComponent = () => {
  const dispatch = useDispatch()
  const selectedParkDetails: ParkDetails | null = useSelector(selectedParkDetailsSelector)
  if (!selectedParkDetails) return

  const { reference, activations, dxccEntity, voivodeships, grid6, coords, numGeometries } = selectedParkDetails
  // const hasArea = area && numGeometries
  const hasArea = !!numGeometries

  // TODO: this should come from api
  // const flagName = flagsService.parkOrSummitCountryToFlag(reference.split('-')[0])
  const flagName = reference.split('-')[0].toLowerCase()

  return (
    <div style={{ marginBottom: 16 }}>
      <Table aria-label="table">
        <TableBody>
          <TableRow>
            <TableCell align="left">DXCC Entity</TableCell>
            <TableCell align="right">
              <span className={`fi fi-${flagName}`} style={{ marginRight: 4, boxShadow: '0px 0px 6px' }}></span> <b>{dxccEntity}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Voivodeship(s)</TableCell>
            <TableCell align="right">
              <b>{voivodeships}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Locator grid(s)</TableCell>
            <TableCell align="right">
              <b>{grid6}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Geo coordinates</TableCell>
            <TableCell align="right">
              <b>{coords.coordinates.join(', ')}</b>
            </TableCell>
          </TableRow>
          {hasArea ? (
            <>
              {/* <TableRow>
                <TableCell align="left">Area</TableCell>
                <TableCell align="right">
                  <b>
                    {(area / 1000).toFixed(2)} km<sup>2</sup>
                  </b>
                </TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell align="left">Number of geometries</TableCell>
                <TableCell align="right">
                  <b>{numGeometries}</b>
                </TableCell>
              </TableRow>
            </>
          ) : (
            <>
              <TableRow>
                <TableCell align="left">
                  <InfoChip text="No geometry defined" />
                </TableCell>
                <TableCell align="right">
                  <Button variant="contained" size="small" onClick={() => dispatch(notImplementedSnack())} startIcon={<AddCircleIcon />}>
                    Add spatial
                  </Button>
                </TableCell>
              </TableRow>
            </>
          )}
          {activations === 0 && (
            <TableRow>
              <TableCell align="left">Activations</TableCell>
              <TableCell align="right">{activations === 0 && <InfoChip text="Never activated" />}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}
