const TOKEN_KEY = 'mappToken'

const getToken = () => localStorage.getItem(TOKEN_KEY)

const setToken = (token: string): void => localStorage.setItem(TOKEN_KEY, token)

const removeToken = (): void => localStorage.removeItem(TOKEN_KEY)

const isTokenValid = () => !!getToken()

export const authenticationService = {
  getToken,
  setToken,
  removeToken,
  isTokenValid,
}
