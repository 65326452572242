/* eslint-disable @typescript-eslint/no-explicit-any */
import * as L from 'leaflet'

export const replace = (arr: any) => arr.map((x: any) => (x.length === 2 && typeof x[0] === 'number' && typeof x[1] === 'number' ? [x[1], x[0]] : replace(x)))

export const leafletBoundsToString = (bounds: L.LatLngBounds) => `n=${bounds.getNorth()}&e=${bounds.getEast()}&s=${bounds.getSouth()}&w=${bounds.getWest()}`

// export const debounce = (callback: any, wait = 500) => {
//   let timeoutId: number = 0
//   return (...args: any) => {
//     // window.clearTimeout(timeoutId)

//     timeoutId = window.setTimeout(() => {
//       callback.apply(null, args)
//       timeoutId = 0
//     }, wait)
//   }
// }

export const debounce = (fn: any, delay: number) => {
  let timeoutId: NodeJS.Timeout | null
  return (...args: any) => {
    if (timeoutId) clearTimeout(timeoutId)

    timeoutId = setTimeout(() => {
      fn.call(...args)
      timeoutId = null
    }, delay)
  }
}
