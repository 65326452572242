import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { UserState } from './user.slice'

const selectUserState = (state: RootState) => state.user

export const isLoggedInSelector = createSelector(selectUserState, (state: UserState) => state.email !== null)
export const getTokenSelector = createSelector(selectUserState, (state: UserState) => state.token)
export const getEmailSelector = createSelector(selectUserState, (state: UserState) => state.email)
export const getCallsignSelector = createSelector(selectUserState, (state: UserState) => state.callsign)
