import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { SearchState } from './search.slice'

const searchStateSelector = (state: RootState): SearchState => state.search

export const isSearchingSelector = createSelector(searchStateSelector, (state: SearchState) => state.searching)

export const isLoadingPointResultsSelector = createSelector(searchStateSelector, (state: SearchState) => state.loadingPointResults)

export const getSearchTermSelector = createSelector(searchStateSelector, (state: SearchState) => state.searchTerm)

export const getSearchResultsSelector = createSelector(searchStateSelector, (state: SearchState) => state.results)

export const getPointResultsSelector = createSelector(searchStateSelector, (state: SearchState) => state.pointResults)
