export const ApiUrl = () => {
  if (import.meta.env.VITE_API_URL) {
    return import.meta.env.VITE_API_URL
  }

  // console.log('import.meta', import.meta)
  // console.log('import.meta.env', import.meta.env)
  // console.log('proccess.env', process.env)

  const result = window.origin.includes('hammap.io') ? 'https://api.hammap.io' : 'https://hammap-api-test.codesign.eu'
  // console.log('result', result)

  return result
}
