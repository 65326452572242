import { createSelector } from '@reduxjs/toolkit'
import { MapState } from './map.slice'
import { RootState } from '../../store'

const selectMapState = (state: RootState) => state.map

export const getSnackbar = createSelector(selectMapState, (state: MapState) => state.snackbar)
export const getMapBounds = createSelector(selectMapState, (state: MapState) => state.bounds)

export const isCreateActivationSpotOpenSelector = createSelector(selectMapState, (state: MapState) => state.activationSpotModal)
export const infoModalVisibleSelector = createSelector(selectMapState, (state: MapState) => state.infoModal)
