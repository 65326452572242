/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
// import NotListedLocationIcon from '@mui/icons-material/NotListedLocation'
import ExploreIcon from '@mui/icons-material/Explore'
import { useSelector } from 'react-redux'
import { isLoggedInSelector } from '../../user/user.selectors'

const Container = styled.div`
  position: absolute;
  right: 0.25rem;
  top: 50%;
  z-index: 1000;

  button {
    transition: ease all 0.3s;
  }
  &:hover button {
    color: blue;
  }
`

export const PointComponent = ({ pointClickInitialize }: any) => {
  const isLoggedIn = useSelector(isLoggedInSelector)

  if (!isLoggedIn) return

  return (
    <Container>
      <IconButton onClick={pointClickInitialize} className="explore-button">
        <Tooltip title="Click and drop marker on place you wan't to check" placement="right">
          <ExploreIcon fontSize="large" />
        </Tooltip>
      </IconButton>
    </Container>
  )
}
