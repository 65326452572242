/* eslint-disable @typescript-eslint/no-explicit-any */
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import AddLocationIcon from '@mui/icons-material/AddLocation'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import WarningIcon from '@mui/icons-material/Warning'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedObject } from './aside.selectors'
import { SelectedObjectType } from './aside.model'
import { AsideBottomContainer, BottomNavigationStyled } from './Aside.styles'
// import { notImplementedSnack } from '../map.slice'
import { commentModalOpen, issueModalOpen } from './components/comment/comment.slice'
import { activationSpotModalOpen } from '../map.slice'

export const AsideBottom = ({ addActivationSpotClick }: any) => {
  const dispatch = useDispatch()
  const selectedObject = useSelector(getSelectedObject)
  const addActivationPointDisabled = selectedObject?.type !== SelectedObjectType.POINT || !selectedObject?.geo

  const onCreateActivationPointClick = () => {
    dispatch(activationSpotModalOpen())
  }

  const onAddCommentClick = () => {
    dispatch(commentModalOpen())
  }

  const onAddIssueClick = () => {
    dispatch(issueModalOpen())
  }

  return (
    <AsideBottomContainer>
      <BottomNavigationStyled showLabels>
        {/* <BottomNavigationAction label="Give us feedback" icon={<HelpIcon />} /> */}
        {selectedObject?.type !== SelectedObjectType.POINT && (
          <BottomNavigationAction label="Report an issue" icon={<WarningIcon />} onClick={onAddIssueClick} />
        )}
        {selectedObject?.type !== SelectedObjectType.POINT && (
          <BottomNavigationAction label="Write a comment" icon={<ChatBubbleIcon />} onClick={onAddCommentClick} />
        )}
        {selectedObject?.type !== SelectedObjectType.POINT && (
          <BottomNavigationAction label="Add activation spot" icon={<AddLocationIcon />} onClick={addActivationSpotClick} />
        )}
        {selectedObject?.type === SelectedObjectType.POINT && (
          <BottomNavigationAction
            label="Create activation spot"
            icon={<AddLocationIcon color={addActivationPointDisabled ? 'disabled' : 'primary'} />}
            onClick={onCreateActivationPointClick}
            disabled={addActivationPointDisabled}
          />
        )}
      </BottomNavigationStyled>
    </AsideBottomContainer>
  )
}
