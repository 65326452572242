import { useDispatch, useSelector } from 'react-redux'
import { getSelectedObject } from './aside.selectors'
import Button from '@mui/material/Button'
import CameraAltIcon from '@mui/icons-material/CameraAlt'
import { SelectedObjectType } from './aside.model'
import { AsideTopContainer } from './Aside.styles'
import { notImplementedSnack } from '../map.slice'

export const AsideTop = () => {
  const referenceObject = useSelector(getSelectedObject)
  const dispatch = useDispatch()
  const defaultBackgroundImage = referenceObject?.type === SelectedObjectType.SUMMIT ? 'summit_placeholder.jpg' : 'park_placeholder.jpg'

  const handleButtonClick = (e: React.SyntheticEvent) => {
    e.stopPropagation()
    alert('Button clicked. Under construction.')
    dispatch(notImplementedSnack())
  }

  const handlePhotoClick = () => {
    alert('Photo clicked. Under construction.')
    dispatch(notImplementedSnack())
  }

  return (
    <AsideTopContainer style={{ backgroundImage: `url(${defaultBackgroundImage})` }} onClick={handlePhotoClick}>
      <div>
        <div></div>
        <div>
          <Button variant="contained" size="small" onClick={handleButtonClick} startIcon={<CameraAltIcon />}>
            Upload photo
          </Button>
        </div>
      </div>
    </AsideTopContainer>
  )
}
