import styled from 'styled-components'
import { AppBarComponent } from './components/AppBar.component'
import { DrawerComponent } from './components/Drawer.component'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getCallsignSelector, isLoggedInSelector } from '../user/user.selectors'

const ContentContainer = styled.div`
  padding: 1rem;
`

export const AdmContainer = () => {
  const isLoggedIn = useSelector(isLoggedInSelector)
  const callsign = useSelector(getCallsignSelector)
  // console.log(isLoggedIn)
  // console.log(callsign)
  if (!isLoggedIn || callsign !== 'SP5ORI') {
    return <Navigate to="/" />
  }

  return (
    <div style={{ marginLeft: 240 }}>
      <AppBarComponent />
      <DrawerComponent />
      <ContentContainer>
        <Outlet />
      </ContentContainer>
    </div>
  )
}

// https://github.com/mui/material-ui/tree/v5.15.7/docs/data/material/getting-started/templates/dashboard
