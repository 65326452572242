import { useDispatch, useSelector } from 'react-redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { InfoChip } from '../../../../../common/components'
import { Voivodeship } from '../../../voivodeships/voivodeships.model'
import { selectedVoivodeshipDetailsSelector } from '../../../voivodeships/voivodeships.selectors'
import Button from '@mui/material/Button'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { notImplementedSnack } from '../../../map.slice'

export const VoivodeshipDetailsComponent = () => {
  const dispatch = useDispatch()
  const selectedVoivodeshipDetails: Voivodeship | null = useSelector(selectedVoivodeshipDetailsSelector)
  if (!selectedVoivodeshipDetails) return

  const { parksCount, parksNotActivatedCount, geo, numberOfGeometries, area } = selectedVoivodeshipDetails
  // const hasArea = area && numberOfGeometries
  const hasArea = !!numberOfGeometries

  return (
    <div style={{ marginBottom: 16 }}>
      <Table aria-label="table">
        <TableBody>
          {hasArea ? (
            <>
              <TableRow>
                <TableCell align="left">Area</TableCell>
                <TableCell align="right">
                  <b>
                    {area} km<sup>2</sup>
                  </b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">Number of geometries</TableCell>
                <TableCell align="right">
                  <b>{numberOfGeometries}</b>
                </TableCell>
              </TableRow>
            </>
          ) : (
            <>
              <TableRow>
                <TableCell align="left">
                  <InfoChip text="No geometry defined" />
                </TableCell>
                <TableCell align="right">
                  <Button variant="contained" size="small" onClick={() => dispatch(notImplementedSnack())} startIcon={<AddCircleIcon />}>
                    Add spatial
                  </Button>
                </TableCell>
              </TableRow>
            </>
          )}
          <TableRow>
            <TableCell align="left">Geo coordinates</TableCell>
            <TableCell align="right">
              <b>{JSON.parse(geo).coordinates.join(', ')}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Parks count / not activated</TableCell>
            <TableCell align="right">
              <b>
                {parksCount} / {parksNotActivatedCount}
              </b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Summits count / not activated</TableCell>
            <TableCell align="right">
              <b>{/* {parksCount} / {parksNotActivatedCount} */}</b>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left">Counties</TableCell>
            <TableCell align="right">
              <b>{/* {parksCount} / {parksNotActivatedCount} */}</b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}
