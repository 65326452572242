import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { SpotsState } from './spots.slice'

const selectSpotsState = (state: RootState) => state.spots

export const asyncSelector = createSelector(selectSpotsState, (state: SpotsState) => state.async)

export const isSuccessSelector = createSelector(selectSpotsState, (state: SpotsState) => state.isSuccess)

export const spotsForBoundsSelector = createSelector(selectSpotsState, (state: SpotsState) => state.spotsForBounds)
