/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  z-index: 5000;
  left: 250px;
  bottom: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 8px;
  width: 400px;
  height: 40%;

  .list {
    margin: 12px 0;
    height: calc(100% - 50px);
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
  }

  input {
    width: 80%;
  }

  div.v {
    background-color: #f0f0f0;
    border-radius: 4px;
    padding: 4px 8px;
    margin-left: 4px;
    margin-bottom: 4px;

    &:hover {
      cursor: pointer;
      background-color: #e0e0e0;
    }
    &.selected {
      color: red;
    }
  }

  p {
    margin: 0;
    padding: 6px;
    font-family: 'Exo 2', sans-serif;
    font-size: 13px;

    &:hover {
      background-color: lightyellow;
      cursor: pointer;
    }

    &.selected {
      color: red;
    }
  }
`

export const LoadFile = ({ onLoad, voivodeships, onVoivodeshipClick, selectedVoivodeship }: any) => {
  const [searchTerm, setSearchTerm] = useState('')

  return (
    <Container>
      <div style={{ padding: '8px 4px', fontSize: '12px' }}>
        <input value={searchTerm} onChange={(e: any) => setSearchTerm(e.target.value)} />
        <button onClick={() => onLoad(searchTerm)}>Load</button>
      </div>
      <div className="list">
        {voivodeships &&
          voivodeships.map((v: any) => {
            return (
              <div key={`v${v.id}`} onClick={() => onVoivodeshipClick(v.id)} className={selectedVoivodeship === v.id ? 'v selected' : 'v'}>
                {v.name}
              </div>
            )
          })}
      </div>
    </Container>
  )
}
