import { http } from '../../../../common/http/http.service'

const BASE_URL = '/adm/users'

const getAll = async () => {
  const { data } = await http().get(`${BASE_URL}`)
  return data
}

export const usersAdmService = {
  getAll,
}
