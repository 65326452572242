import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  position: absolute;
  bottom: 16px;
  justify-content: center;
  width: 100%;
  z-index: 500;
  font-weight: 800;
  user-select: none;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;

  img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  span {
    margin-top: 2px;
  }
`

export const FooterArea = () => {
  let title: string
  switch (window.location.origin) {
    case 'https://hammap.io':
      title = 'hammap'
      break
    case 'https://www.hammap.io':
      title = 'hammap'
      break
    case 'https://hammap-test.codesign.eu':
      title = 'hammap test'
      break
    default:
      title = 'hammap localhost'
      break
  }

  return (
    <Container>
      <img src="favicon.svg" alt="" />
      <span>{title}</span>
    </Container>
  )
}
