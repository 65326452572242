/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { PointResult, SearchResult } from './search.model'
import { http } from '../../../common/http/http.service'
import { GeoPoint } from '../../../common/models/geo.model'

export interface SearchState {
  searching: boolean
  searchTerm: string
  results: SearchResult[]
  loadingPointResults: boolean
  pointResults: PointResult[]
}

const initialState: SearchState = {
  searching: false,
  searchTerm: '',
  results: [],
  loadingPointResults: false,
  pointResults: [],
}

export const search = createAsyncThunk('search/mainSearch', async (searchTerm: string) => {
  const response = await http().get('/search?query=' + searchTerm)
  return response.data
})

export const explorePoint = createAsyncThunk('search/explorePoint', async (geo: GeoPoint) => {
  const response = await http().get(`/search/point?latitude=${geo[1]}&longitude=${geo[0]}`)
  return response.data
})

export const searchState = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchTerm: (state: SearchState, action: PayloadAction<string>) => {
      const { payload } = action
      state.searchTerm = payload
      if (!payload) {
        state.results = []
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(search.pending, (state: SearchState) => {
      state.searching = true
    })
    builder.addCase(search.fulfilled, (state: SearchState, action: PayloadAction<any>) => {
      const { payload } = action

      state.searching = false
      state.results = payload
    })
    builder.addCase(explorePoint.pending, (state: SearchState) => {
      state.loadingPointResults = true
    })
    builder.addCase(explorePoint.fulfilled, (state: SearchState, action: PayloadAction<PointResult[]>) => {
      const { payload } = action

      state.loadingPointResults = false
      state.pointResults = payload
    })
  },
})

export const { setSearchTerm } = searchState.actions

export default searchState.reducer
