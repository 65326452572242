/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { SignInResponseType } from './user.model'
import { http } from '../../common/http/http.service'

export type UserState = {
  isLoading: boolean
  email: string | null
  callsign: string | null
  token: string | null
}

const initialState: UserState = {
  isLoading: false,
  email: null,
  callsign: null,
  token: null,
}

export const getProfile = createAsyncThunk('getProfile', async () => {
  // console.log('getProfile')
  const response = await http().get('/profile/me')
  return response.data
})

export const userState = createSlice({
  name: 'user',
  initialState,
  reducers: {
    set: (state: UserState, action: PayloadAction<SignInResponseType>) => {
      const { email, callsign, token } = action.payload
      state.email = email
      state.callsign = callsign
      state.token = token
    },
    clear: (state: UserState) => {
      state.email = null
      state.callsign = null
      state.token = null
    },
  },
  extraReducers: builder => {
    builder.addCase(getProfile.pending, (state: UserState) => {
      state.isLoading = true
    })
    builder.addCase(getProfile.fulfilled, (state: UserState, action: PayloadAction<any>) => {
      const { payload } = action
      const { email, callsign } = payload
      state.isLoading = false
      state.email = email
      state.callsign = callsign
    })
    builder.addCase(getProfile.rejected, (state: UserState) => {
      state.isLoading = false
    })
  },
})

export const { set, clear } = userState.actions

export default userState.reducer
