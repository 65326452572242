import { useDispatch, useSelector } from 'react-redux'
import { selectedSummitRelatedParksSelector } from '../../../summits/summits.selectors'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import { InfoChip } from '../../../../../common/components'
import AccordionDetails from '@mui/material/AccordionDetails'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import styled from 'styled-components'
import TableCell from '@mui/material/TableCell'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { RelatedPark } from '../../../summits/summits.model'
import { setSelectedObject } from '../../aside.slice'
import { SelectedObjectType } from '../../aside.model'

const TableRowStyled = styled(TableRow)`
  &:hover {
    background-color: #ececec;
    cursor: pointer;
  }
`

export const SummitRelatedParksComponent = () => {
  const dispatch = useDispatch()
  const relatedParks = useSelector(selectedSummitRelatedParksSelector)
  if (!relatedParks) {
    return
  }

  const handleRowClick = (relation: RelatedPark) => {
    dispatch(
      setSelectedObject({
        reference: relation.reference,
        name: relation.name,
        type: SelectedObjectType.PARK,
        geo: relation.coords.coordinates,
      })
    )
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <b style={{ marginRight: '0.5rem' }}>In parks</b>
        <InfoChip text={relatedParks.length.toString()} />
      </AccordionSummary>
      <AccordionDetails sx={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
        <Table aria-label="table" size="small">
          <TableBody>
            {relatedParks.map((relation: RelatedPark) => {
              return (
                <TableRowStyled key={relation.reference} onClick={() => handleRowClick(relation)}>
                  <TableCell>
                    <b>{relation.reference}</b>
                    <br />
                    {relation.name}
                  </TableCell>
                </TableRowStyled>
              )
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}
