/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { LatLngBounds } from '../../../common/models/geo.model'
import { http } from '../../../common/http/http.service'
import { leafletBoundsToString } from '../../../services/utils'
import { Voivodeship } from './voivodeships.model'
import { Comment } from '../aside/components/comment/comment.model'

export interface VoivodeshipsState {
  loading: boolean
  loadingDetails: boolean
  loadingComments: boolean
  voivodeshipsForBounds: Voivodeship[]
  selectedVoivodeshipDetails: Voivodeship | null
  selectedVoivodeshipComments: Comment[] | null
}

const initialState: VoivodeshipsState = {
  loading: false,
  loadingDetails: false,
  loadingComments: false,
  voivodeshipsForBounds: [],
  selectedVoivodeshipDetails: null,
  selectedVoivodeshipComments: null,
}

export const getVoivodeshipsForBounds = createAsyncThunk('getVoivodeshipsForBounds', async (bounds: LatLngBounds) => {
  const response = await http().get(`/voivodeships?${leafletBoundsToString(bounds)}`)
  return response.data
})

export const getVoivodeshipDetails = createAsyncThunk('getVoivodeshipDetails', async (reference: string) => {
  const response = await http().get(`/voivodeships/${reference}`)
  return response.data
})

export const getVoivodeshipsComments = createAsyncThunk('getVoivodeshipsComments', async (reference: string) => {
  const response = await http().get(`/voivodeships/${reference}/comments`)
  return response.data
})

export const voivodeshipsState = createSlice({
  name: 'voivodeships',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getVoivodeshipsForBounds.pending, (state: VoivodeshipsState) => {
      state.loading = true
    })
    builder.addCase(getVoivodeshipsForBounds.fulfilled, (state: VoivodeshipsState, action: PayloadAction<any>) => {
      const { payload } = action

      state.loading = false
      state.voivodeshipsForBounds = payload
    })
    builder.addCase(getVoivodeshipsForBounds.rejected, (state: VoivodeshipsState) => {
      state.loading = false
    })
    builder.addCase(getVoivodeshipDetails.pending, (state: VoivodeshipsState) => {
      state.loading = true
    })
    builder.addCase(getVoivodeshipDetails.fulfilled, (state: VoivodeshipsState, action: PayloadAction<any>) => {
      const { payload } = action

      state.loading = false
      state.selectedVoivodeshipDetails = payload
    })
    builder.addCase(getVoivodeshipDetails.rejected, (state: VoivodeshipsState) => {
      state.loading = false
    })
    builder.addCase(getVoivodeshipsComments.pending, (state: VoivodeshipsState) => {
      state.loadingComments = true
    })
    builder.addCase(getVoivodeshipsComments.fulfilled, (state: VoivodeshipsState, action: PayloadAction<any[]>) => {
      const { payload } = action

      state.loadingComments = false
      state.selectedVoivodeshipComments = payload
    })
    builder.addCase(getVoivodeshipsComments.rejected, (state: VoivodeshipsState) => {
      state.loadingComments = false
    })
  },
})

export default voivodeshipsState.reducer
