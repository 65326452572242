import { useDispatch, useSelector } from 'react-redux'
import Modal from '@mui/material/Modal'

import { authComponentSelector, isModalOpenSelector } from '../auth.selectors'
import { close } from '../auth.slice'
import { SignInComponent } from './SignIn.component'
import CssBaseline from '@mui/material/CssBaseline'
import { AuthFormType } from '../auth.model'
import { SignUpComponent } from './SignUp.component'
import { ResetPasswordComponent } from './ResetPassword.component'
import { ModalFormContainer } from '../../../common/layout/Modal.styles'

export const ModalAuthenticationComponent = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(isModalOpenSelector)
  // const [authComponent, setAuthComponent] = useState<AuthFormType>(AuthFormType.SIGNIN)
  const authComponent = useSelector(authComponentSelector)
  const handleClose = () => dispatch(close())

  const ComponentView = {
    [AuthFormType.SIGNIN]: SignInComponent,
    [AuthFormType.SIGNUP]: SignUpComponent,
    [AuthFormType.RESET_PASSWORD]: ResetPasswordComponent,
  }

  const Component = ComponentView[authComponent]

  if (!isOpen) return

  return (
    <>
      <CssBaseline />
      <Modal keepMounted open={isOpen} onClose={handleClose}>
        <ModalFormContainer elevation={3}>
          <Component />
        </ModalFormContainer>
      </Modal>
    </>
  )
}
