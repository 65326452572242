import InfoIcon from '@mui/icons-material/Info'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import { useDispatch } from 'react-redux'
import { toggleInfoModal } from '../map.slice'

export const InfoComponent = () => {
  const dispatch = useDispatch()
  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  const handleClick = () => {
    dispatch(toggleInfoModal(true))
  }
  return (
    <div>
      <IconButton onClick={handleClick} disableRipple>
        <Tooltip title="Info" placement="left">
          <InfoIcon sx={{ fontSize: 48, marginRight: '2px', color: 'green' }} />
        </Tooltip>
      </IconButton>
    </div>
  )
}
