/* eslint-disable @typescript-eslint/no-explicit-any */
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Avatar from '@mui/material/Avatar'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { signInSchema } from '../auth.schema'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { AuthFormType } from '../auth.model'
import { useDispatch, useSelector } from 'react-redux'
import { authComponentChange, close, signIn } from '../auth.slice'
import { getTokenSelector, signInErrorSelector, signInInProgressSelector, signInSuccessSelector } from '../auth.selectors'
import { useEffect } from 'react'
import { getProfile } from '../../user/user.slice'
import { authenticationService } from '../../../common/services/authentication.service'
import { getFlags } from '../../map/flags/flags.slice'
import { AppDispatch } from '../../../store'

export const SignInComponent = () => {
  const dispatch = useDispatch<AppDispatch>()
  const isError = useSelector(signInErrorSelector)
  const isSuccess = useSelector(signInSuccessSelector)
  const isLoading = useSelector(signInInProgressSelector)
  const token = useSelector(getTokenSelector)

  useEffect(() => {
    if (isSuccess && token) {
      authenticationService.setToken(token)
      // TODO: clearTimeout for all setTimeouts?
      setTimeout(() => {
        dispatch(getProfile())
        dispatch(getFlags())
        dispatch(close())
      }, 500)
    }
  }, [isSuccess, token, dispatch])

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(signInSchema),
  })

  const onSubmit = async (payload: any) => {
    try {
      dispatch(signIn(payload))
      // console.log(result)
      // console.log('onSubmit', payload)
    } catch (e) {
      // console.log('ERR', e)
    }
  }

  return (
    <>
      <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <h1 data-testid="signin-header">Sign In</h1>
      <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
        <TextField
          {...register('email')}
          data-testid="signin-email"
          margin="normal"
          required
          fullWidth
          label="Email"
          type="text"
          autoComplete="off"
          error={errors?.email ? true : false}
          helperText={errors?.email?.message?.toString()}
        />
        <TextField
          {...register('password')}
          data-testid="signin-password"
          margin="normal"
          required
          fullWidth
          label="Password"
          type="password"
          autoComplete="off"
          error={errors?.password ? true : false}
          helperText={errors?.password?.message?.toString()}
        />
        {isError && (
          <Alert severity="error">
            <AlertTitle>Ooops</AlertTitle>
            This email or password is incorrect
          </Alert>
        )}
        <Button disabled={!isValid || isLoading} type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
          Sign In
        </Button>
        <ul>
          <li>
            <Link underline="always" onClick={() => dispatch(authComponentChange(AuthFormType.RESET_PASSWORD))}>
              Forgot password?
            </Link>
          </li>
          <li>
            <Link underline="always" onClick={() => dispatch(authComponentChange(AuthFormType.SIGNUP))}>
              Don&rsquo;t have an account? Sign up
            </Link>
          </li>
        </ul>
      </Box>
    </>
  )
}
