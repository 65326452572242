import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { selectedParkRelationsSelector } from '../../../parks/parks.selectors'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { ParkRelation, ParkRelationType } from '../../../parks/parks.model'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { InfoChip } from '../../../../../common/components'
import { setSelectedObject } from '../../aside.slice'
import { SelectedObjectType } from '../../aside.model'

const TableRowStyled = styled(TableRow)`
  &:hover {
    background-color: #ececec;
    cursor: pointer;
  }
`

export const ParkRelationsComponent = () => {
  const dispatch = useDispatch()
  const parkRelations = useSelector(selectedParkRelationsSelector)
  if (!parkRelations || !parkRelations.length) return

  const handleRowClick = (relation: ParkRelation) => {
    dispatch(
      setSelectedObject({
        reference: relation.reference,
        name: relation.name,
        type: SelectedObjectType.PARK,
        geo: relation.coords.coordinates,
      })
    )
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <b style={{ marginRight: '0.5rem' }}>Spatial relations</b>
        {/* <InfoChip text={`${activations.length} ${activations.length === 1 ? 'activation' : 'activations'}`} /> */}
        <InfoChip text={parkRelations.length.toString()} />
      </AccordionSummary>
      <AccordionDetails sx={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
        <Table aria-label="table" size="small">
          <TableBody>
            {parkRelations.map((relation: ParkRelation) => {
              return (
                <TableRowStyled key={relation.reference} onClick={() => handleRowClick(relation)}>
                  <TableCell>
                    <b>{relation.reference}</b>, Reference type: <b>{Object.values(ParkRelationType)[Object.keys(ParkRelationType).indexOf(relation.type)]}</b>
                    <br />
                    {relation.name}
                  </TableCell>
                </TableRowStyled>
              )
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}
