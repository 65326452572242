/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { http } from '../../../common/http/http.service'
import { LatLngBounds } from '../../../common/models/geo.model'
import { leafletBoundsToString } from '../../../services/utils'
// import { Comment } from '../aside/components/comment/comment.model'
import { ActivationSpot } from './spots.model'
import { ActivationSpotCreateRequest } from '../aside/components/spot/spot.model'

export interface SpotsState {
  async: boolean
  isSuccess: boolean
  selectedSpotDetails: any | undefined
  spotsForBounds: ActivationSpot[]
}

const initialState: SpotsState = {
  async: false,
  isSuccess: false,
  selectedSpotDetails: undefined,
  spotsForBounds: [],
}

export const getSpotsForBounds = createAsyncThunk('getSpotsForBounds', async (bounds: LatLngBounds) => {
  const response = await http().get(`/spots?${leafletBoundsToString(bounds)}`)
  return response.data
})

export const createActivationSpot = createAsyncThunk('createActivationSpot', async (payload: ActivationSpotCreateRequest) => {
  // console.log(payload)
  const response = await http().post('/spots', payload)
  return response.data
})

// export const addComment = createAsyncThunk('addComment', async (payload: AddCommentRequest) => {
//   const response = await http().post('/comments', payload)
//   return response.data
// })

export const spotsState = createSlice({
  name: 'spots',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getSpotsForBounds.pending, (state: SpotsState) => {
      state.async = true
    })
    builder.addCase(getSpotsForBounds.fulfilled, (state: SpotsState, action: PayloadAction<any>) => {
      const { payload } = action

      state.async = false
      state.spotsForBounds = payload
    })
    builder.addCase(getSpotsForBounds.rejected, (state: SpotsState) => {
      state.async = false
    })
    builder.addCase(createActivationSpot.pending, (state: SpotsState) => {
      state.async = true
      state.isSuccess = false
    })
    builder.addCase(createActivationSpot.fulfilled, (state: SpotsState) => {
      // builder.addCase(createActivationSpot.fulfilled, (state: SpotsState, action: PayloadAction<any>) => {
      // const { payload } = action
      // console.log(payload)

      state.async = false
      state.isSuccess = true
      // state.spotsForBounds = payload
    })
    builder.addCase(createActivationSpot.rejected, (state: SpotsState) => {
      state.async = false
    })
  },
})

export default spotsState.reducer
