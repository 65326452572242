/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { LatLngBounds } from '../../../common/models/geo.model'
import { http } from '../../../common/http/http.service'
import { leafletBoundsToString } from '../../../services/utils'
import { Country } from './countries.model'
import { Comment } from '../aside/components/comment/comment.model'

export interface CountriesState {
  loading: boolean
  loadingComments: boolean
  countriesForBounds: Country[]
  selectedCountryComments: Comment[] | null
}

const initialState: CountriesState = {
  loading: false,
  loadingComments: false,
  countriesForBounds: [],
  selectedCountryComments: null,
}

export const getCountriesForBounds = createAsyncThunk('getCountriesForBounds', async (bounds: LatLngBounds) => {
  const response = await http().get(`/countries?${leafletBoundsToString(bounds)}`)
  return response.data
})

export const getCountryComments = createAsyncThunk('getCountryComments', async (reference: string) => {
  const response = await http().get(`/countries/${reference}/comments`)
  return response.data
})

export const countriesState = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCountriesForBounds.pending, (state: CountriesState) => {
      state.loading = true
    })
    builder.addCase(getCountriesForBounds.fulfilled, (state: CountriesState, action: PayloadAction<any>) => {
      const { payload } = action

      state.loading = false
      state.countriesForBounds = payload
    })
    builder.addCase(getCountriesForBounds.rejected, (state: CountriesState) => {
      state.loading = false
    })
    builder.addCase(getCountryComments.pending, (state: CountriesState) => {
      state.loadingComments = true
    })
    builder.addCase(getCountryComments.fulfilled, (state: CountriesState, action: PayloadAction<any[]>) => {
      const { payload } = action

      state.loadingComments = false
      state.selectedCountryComments = payload
    })
    builder.addCase(getCountryComments.rejected, (state: CountriesState) => {
      state.loadingComments = false
    })
  },
})

// export const { setSelectedParkStore, clearSelecteParkStore } = parksState.actions

export default countriesState.reducer
