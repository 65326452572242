/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { MapContainer, Polygon, TileLayer } from 'react-leaflet'
import { MapContainerStyles } from '../../map/Map.styles'
import { LatLngExpression } from 'leaflet'
import { replace } from '../../../services/utils'
import { http } from '../../../common/http/http.service'
import { ApiUrl } from '../../../common/services/config.service'
// import { FilesList } from './FilesList'
import { CountriesList } from './CountriesList'
import { MatchButton } from './Match'
import { LoadFile } from './LoadFile'

export const CountriesPage = () => {
  const [mapCenter] = useState<LatLngExpression>([52.4, 20.9])
  // const [listOfFiles, setListOfFiles] = useState<any>()
  const [listOfCountries, setListOfCountries] = useState<any>()
  const [selectedArea, setSelectedArea] = useState<any>()
  const [selectedFile, setSelectedFile] = useState<string | null>()
  const [selectedCountry, setSelectedCountry] = useState<any>()

  useEffect(() => {
    loadCountries()
    // onFileListLoad()
  }, [])

  const loadCountries = async () => {
    const { data } = await http().get(`${ApiUrl()}/countries/list`)
    // console.log(data)
    setListOfCountries(data)
  }

  // const onFileListLoad = async () => {
  //   // const response = await http().get('https://serwer2308310.home.pl/gdz_pl/_mapp/countries/')
  //   const response = await http().get('https://serwer2308310.home.pl/gdz_pl/_mapp/countries/')
  //   const result = await response.data
  //   // console.log(result)
  //   const anchors = result.match(/<a[^>]*>([^<]+)<\/a>/g)
  //   const mapped = anchors.map((x: string) => {
  //     const m = x.match(/href="([^"]*)/)
  //     if (m) {
  //       return m[1]
  //     }
  //   })
  //   // console.log(mapped)
  //   // setListOfFiles(mapped)
  // }

  // const onFileClick = async (file: string) => {
  //   if (file === selectedFile) {
  //     setSelectedFile(null)
  //     setSelectedArea(null)
  //     return
  //   }

  //   const response = await http().get(`https://serwer2308310.home.pl/gdz_pl/_mapp/countries/${file}`)
  //   const result = await response.data

  //   setSelectedFile(file)
  //   setSelectedArea(result)
  // }

  const onLoadHandle = async (filePath: string) => {
    const file = await fetch('/co/' + filePath)
    const response = await file.json()

    setSelectedFile(filePath)
    setSelectedArea(response)
  }

  const onCountryClick = (country: any) => {
    if (country.prefix === selectedCountry?.prefix) {
      setSelectedCountry(null)
      return
    }

    setSelectedCountry(country)
  }

  const onMatchClick = async () => {
    const metadata = {
      fileName: selectedArea.name,
      crs: selectedArea.crs,
      featuresCount: selectedArea.features.length,
      feature0properties: selectedArea.features[0].properties,
      timestamp: new Date(),
    }
    const input = { prefix: selectedCountry.prefix, geo: selectedArea.features[0].geometry, metadata }
    // console.log(input)
    await http().post(`${ApiUrl()}/countries/area`, input)
    setSelectedFile(null)
    setSelectedCountry(null)
    setSelectedArea(null)
    loadCountries()
  }

  return (
    <div>
      <CountriesList countries={listOfCountries} onCountryClick={onCountryClick} selectedCountry={selectedCountry} />
      {/* <FilesList files={listOfFiles} onFileClick={onFileClick} selectedFile={selectedFile} /> */}
      <LoadFile onLoad={onLoadHandle} />
      {selectedFile && selectedCountry && <MatchButton matchClick={onMatchClick} />}
      <MapContainerStyles>
        <MapContainer center={mapCenter} zoom={6} scrollWheelZoom={true}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {selectedArea && <Polygon pathOptions={{ fillColor: 'yellow', color: 'red' }} positions={replace(selectedArea.features[0].geometry.coordinates)} />}
        </MapContainer>
      </MapContainerStyles>
    </div>
  )
}
