import styled from 'styled-components'

export const MapContainerStyles = styled.div`
  width: 100vw;
  height: 100vh;

  & > div.leaflet-container {
    width: 100vw;
    height: 100vh;
  }
`
export const AdmMapContainerStyles = styled.div`
  width: 100%;
  height: 100%;
  /* border: 5px solid red; */
  display: flex;

  & > div.aside {
    /* border: 5px solid blue; */
    /* flex: 1; */
    width: 400px;
    padding-right: 1rem;
  }

  & > div.leaflet-container {
    flex: 1;
    /* border: 5px solid green; */
    width: 100%;
    height: calc(100vh - 100px);
  }
  textarea {
    width: 100%;
  }
`

export const AsideContainer = styled.div`
  // border: 1px solid;
  position: absolute;
  z-index: 500;
  top: 290px;
  left: 16px;
  width: 380px;
`
