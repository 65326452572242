import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { selectedParkActivationsSelector } from '../../../parks/parks.selectors'
import { ParkActivation } from '../../../parks/parks.model'
import datetimeService from '../../../../../services/datetime.service'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import PersonIcon from '@mui/icons-material/Person'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { InfoChip } from '../../../../../common/components'

const TableRowStyled = styled(TableRow)`
  .MuiTableCell-root {
    & > div {
      display: flex;
      span,
      b {
        white-space: pre;
      }
    }
  }
  .qsos {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  small {
    font-weight: bold;
    font-size: 10px;
    background-color: lightgray;
    padding: 2px 4px;
    border-radius: 4px;
    margin-left: 4px;
    margin-right: 4px;

    &.cw {
      background-color: rgba(0, 255, 0, 0.5);
    }
    &.phone {
      background-color: lightsalmon;
    }
    &.data {
      background-color: lightblue;
    }
  }
`

export const ParkActivationsComponent = () => {
  const activations = useSelector(selectedParkActivationsSelector)
  if (!activations) return

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <b style={{ marginRight: '0.5rem' }}>Activations</b>
        {/* <InfoChip text={`${activations.length} ${activations.length === 1 ? 'activation' : 'activations'}`} /> */}
        <InfoChip text={activations.length.toString()} />
      </AccordionSummary>
      <AccordionDetails sx={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
        <Table aria-label="table" size="small">
          <TableBody>
            {activations.map(({ callsign, date, qsosCW, qsosData, qsosPhone, qsosTotal }: ParkActivation) => {
              return (
                <TableRowStyled key={`${callsign}${date.toString()}${qsosTotal}`}>
                  <TableCell align="left">
                    <div>
                      <CalendarMonthIcon color="primary" fontSize="small" sx={{ marginRight: 1 }} />
                      <span>{datetimeService.formatDay(date)}</span>
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    <div>
                      <PersonIcon color="primary" fontSize="small" sx={{ marginRight: 1 }} />
                      <b>{callsign}</b>
                    </div>
                  </TableCell>
                  <TableCell align="center" sx={{ whiteSpace: 'pre' }}>
                    <div className="qsos">
                      {qsosCW > 0 && (
                        <span>
                          {qsosCW}
                          <small className="cw">CW</small>
                        </span>
                      )}
                      {qsosPhone > 0 && (
                        <span>
                          {qsosPhone}
                          <small className="phone">Phone</small>
                        </span>
                      )}
                      {qsosData > 0 && (
                        <span>
                          {qsosData}
                          <small className="data">Data</small>{' '}
                        </span>
                      )}
                      {qsosCW !== qsosTotal && qsosPhone !== qsosTotal && qsosData !== qsosTotal && (
                        <span>
                          {qsosTotal}
                          <small className="total">total</small>
                        </span>
                      )}
                    </div>
                  </TableCell>
                </TableRowStyled>
              )
            })}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  )
}
