import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { SummitsState } from './summits.slice'

const selectSummitsState = (state: RootState) => state.summits

export const isLoadingSelector = createSelector(selectSummitsState, (state: SummitsState) => state.loading)

export const isLoadingSummitDetailsSelector = createSelector(selectSummitsState, (state: SummitsState) => state.loadingDetails)

export const isLoadingRelatedParksSelector = createSelector(selectSummitsState, (state: SummitsState) => state.loadingRelatedParks)

export const isLoadingNearbySummitsSelector = createSelector(selectSummitsState, (state: SummitsState) => state.loadingNearbySummits)

export const summitsForBoundsSelector = createSelector(selectSummitsState, (state: SummitsState) => state.summitsForBounds)

export const selectedSummitDetailsSelector = createSelector(selectSummitsState, (state: SummitsState) => state.selectedSummitDetails)

export const selectedSummitActivationsSelector = createSelector(selectSummitsState, (state: SummitsState) => state.selectedSummitActivations)

export const selectedSummitRelatedParksSelector = createSelector(selectSummitsState, (state: SummitsState) => state.selectedSummitRelatedParks)

export const selectedSummitNearbySummitsSelector = createSelector(selectSummitsState, (state: SummitsState) => state.selectedSummitNearbySummits)

export const selectedSummitCommentsSelector = createSelector(selectSummitsState, (state: SummitsState) => state.selectedSummitComments)
