/* eslint-disable import/no-unresolved */
import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { Provider } from 'react-redux'
import './styles.css'
import 'leaflet/dist/leaflet.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { store } from './store.ts'
import { authenticationService } from './common/services/authentication.service.ts'
import { getProfile } from './areas/user/user.slice.ts'

// console.log('API_URL', ApiUrl())
import '/node_modules/flag-icons/css/flag-icons.min.css'
import { MainMap } from './areas/map/MainMap.tsx'
import { AdmContainer } from './areas/adm/AdmContainer.page.tsx'
import { UsersPage } from './areas/adm/areas/users/Users.page.tsx'
import { DashboardPage } from './areas/adm/areas/dashboard/Dashboard.page.tsx'
import { ItuZonesPage } from './areas/adm/areas/itu/ItuZones.page.tsx'
import { AuthConfirmPage } from './areas/auth/pages/AuthConfirm.page.tsx'
import { CountriesPage } from './areas/matcher/countries/Countries.tsx'
import { VoivodeshipsPage } from './areas/matcher/voivodeships/Voivodeships.tsx'
import { AuthResetPasswordPage } from './areas/auth/pages/AuthResetPassword.page.tsx'

const darkTheme = createTheme({
  palette: {
    mode: 'light',
  },
})

if (authenticationService.isTokenValid()) {
  store.dispatch(getProfile())
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={darkTheme}>
        {/* <CssBaseline /> */}
        {/* <RouterProvider router={router} /> */}
        <BrowserRouter>
          <Routes>
            <Route path="auth/confirm/:email/:confirmationCode" element={<AuthConfirmPage />}></Route>
            <Route path="auth/reset/:email/:confirmationCode" element={<AuthResetPasswordPage />}></Route>
            <Route path="adm" element={<AdmContainer />}>
              <Route index element={<DashboardPage />} />
              <Route path="users" element={<UsersPage />} />
              <Route path="itu" element={<ItuZonesPage />} />
              <Route path="countries" element={<CountriesPage />} />
              <Route path="voivodeships" element={<VoivodeshipsPage />} />
            </Route>
            <Route index element={<MainMap />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
)
