import { SelectedObjectType } from '../aside/aside.model'

export type Flag = {
  reference: string
  type: FlagType
  object: SelectedObjectType
  flagged?: boolean
}

export enum FlagType {
  BOOKMARK = 'BOOKMARK',
  ACTIVATED = 'ACTIVATED',
  HUNTED = 'HUNTED',
}
