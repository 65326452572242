import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { http } from '../../../../../common/http/http.service'
import { AddCommentRequest } from './comment.model'

export type CommentState = {
  async: boolean
  error?: string
  isModalOpen: boolean
  isIssueModalOpen: boolean
}

const initialState: CommentState = {
  async: false,
  isModalOpen: false,
  isIssueModalOpen: false,
}

export const addComment = createAsyncThunk('addComment', async (payload: AddCommentRequest) => {
  const response = await http().post('/comments', payload)
  return response.data
})

export const addIssue = createAsyncThunk('addIssue', async (payload: AddCommentRequest) => {
  const response = await http().post('/comments/issue', payload)
  return response.data
})

export const authState = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    commentModalOpen: (state: CommentState) => {
      state.isModalOpen = true
    },
    commentModalClose: (state: CommentState) => {
      state.isModalOpen = false
    },
    issueModalOpen: (state: CommentState) => {
      state.isIssueModalOpen = true
    },
    issueModalClose: (state: CommentState) => {
      state.isIssueModalOpen = false
    },
  },
  extraReducers: builder => {
    builder.addCase(addComment.pending, (state: CommentState) => {
      state.async = true
      state.error = undefined
    })
    builder.addCase(addComment.fulfilled, (state: CommentState) => {
      state.async = false
      state.isModalOpen = false
    })
    builder.addCase(addComment.rejected, (state: CommentState) => {
      state.async = false
      state.error = 'err'
    })
  },
})

export const { commentModalOpen, commentModalClose, issueModalOpen, issueModalClose } = authState.actions

export default authState.reducer
