/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActivationSpotCreateRequest, ActivationSpotFeature } from './spot.model'
import { activationSpotModalClose } from '../../../map.slice'
import { addActivationSpotSchema } from './spot.schema'
import { AppDispatch } from '../../../../../store'
import { CommentFormContainer } from '../../../../../common/layout/Modal.styles'
import { getSelectedObject } from '../../aside.selectors'
import { getMapBounds, isCreateActivationSpotOpenSelector } from '../../../map.selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import AddLocationIcon from '@mui/icons-material/AddLocation'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup/ToggleButtonGroup'
import Tooltip from '@mui/material/Tooltip'
import { createActivationSpot, getSpotsForBounds } from '../../../spots/spots.slice'
import { asyncSelector, isSuccessSelector } from '../../../spots/spots.selectors'

const ACCESS_METHODS_OPTONS: ActivationSpotFeature[] = [
  {
    key: 'walk',
    value: 'Walk',
    description: 'Select if the spot is easily accessible by foot',
  },
  {
    key: 'hike',
    value: 'Hike',
    description: 'Select if to reach the spot you need to hike',
  },
  {
    key: 'bicycle',
    value: 'Bicycle',
    description: 'You can get to the spot by the bicycle',
  },
  {
    key: 'car',
    value: 'Car',
    description: 'You can drive to the spot, ant there is a place or parking to leave the car nearby',
  },
  {
    key: '4x4',
    value: '4 × 4',
    description: "The road to the spot is more demanding and you'll need an off-road or overlander vehicle ",
  },
  {
    key: 'sail',
    value: 'Sail',
    description: "You'll need a boat, kayak or a ferry to get there",
  },
  {
    key: 'air',
    value: 'Air',
    description: "You'll need a plane to get to the activation spot",
  },
]

const ACTIVATION_METHODS_OPTONS: ActivationSpotFeature[] = [
  {
    key: 'pedestrian',
    value: 'Pedestrian',
    description: '',
  },
  {
    key: 'car',
    value: 'Car',
    description: 'You can activate strait from the car',
  },
  {
    key: 'boat',
    value: 'Boat',
    description: 'You can activate from a boat or kayak',
  },
  // {
  //   key: 'plane',
  //   value: 'Plane',
  //   description: 'You can activate from plane',
  // },
]

const FACILITIES_OPTONS: ActivationSpotFeature[] = [
  {
    key: 'bench',
    value: 'Bench',
    description: 'Select if there is a bench or any other place where you can sit',
  },
  {
    key: 'table',
    value: 'Table',
    description: 'Select if there is a table or any other place where you put your radio and other gear',
  },
  {
    key: 'canopy',
    value: 'Canopy',
    description: 'Select if there is a canopy or any other type of roofing to protect against the rain',
  },
  {
    key: 'shed',
    value: 'Shed',
    description: 'Select if there is a shed or any other type of protection against the wind',
  },
  {
    key: 'trees',
    value: 'Trees',
    description: 'Select if there is or there are trees to set up a wire antenna',
  },
  {
    key: 'flat',
    value: 'Flat area',
    description: 'Select if there is a piece of flat land to setup ground antennas',
  },
  {
    key: 'nointernet',
    value: 'No internet',
    description: 'Select if there is a lack or GSM range issues',
  },
]

export const ActivationSpotModalComponent = () => {
  const dispatch = useDispatch<AppDispatch>()
  // const [submitted, setSubmitted] = useState(false)
  const isOpen = useSelector(isCreateActivationSpotOpenSelector)
  const async = useSelector(asyncSelector)
  const isSuccess = useSelector(isSuccessSelector)
  const bounds = useSelector(getMapBounds)
  const selectedObject = useSelector(getSelectedObject)
  const [facilities, setFacilities] = useState<string[]>([])
  const [accessMethods, setAccessMethods] = useState<string[]>([])
  const [activationMethods, setActivationMethods] = useState<string[]>([])
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(addActivationSpotSchema),
  })

  useEffect(() => setValue('facilities', facilities, { shouldValidate: true }), [facilities, setValue])
  useEffect(() => setValue('accessMethods', accessMethods, { shouldValidate: true }), [accessMethods, setValue])
  useEffect(() => setValue('activationMethods', activationMethods, { shouldValidate: true }), [activationMethods, setValue])
  useEffect(() => {
    if (isSuccess) {
      dispatch(getSpotsForBounds(bounds!))
      setTimeout(() => {
        dispatch(activationSpotModalClose())
      }, 1000)
    }
  }, [isSuccess, bounds, dispatch])

  const onSubmit = async (payload: any) => {
    try {
      const { name, comment, accessMethods, activationMethods, facilities } = payload
      const { geo } = selectedObject!
      const longitude = geo ? geo[0] : 0
      const latitude = geo ? geo[1] : 0

      const request: ActivationSpotCreateRequest = {
        name,
        comment,
        longitude,
        latitude,
        accessMethods,
        activationMethods,
        facilities,
      }

      dispatch(createActivationSpot(request))
    } catch (e) {
      // console.log('ERR', e)
    }
  }

  const handleClose = () => {
    reset()
    setFacilities([])
    setAccessMethods([])
    setActivationMethods([])
    dispatch(activationSpotModalClose())
  }

  if (!isOpen) return

  return (
    <>
      <Modal keepMounted open={isOpen} onClose={handleClose} style={{ paddingLeft: '412px', height: '100%' }}>
        <CommentFormContainer elevation={3}>
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <AddLocationIcon />
          </Avatar>
          <h1>Create activation spot</h1>
          {selectedObject && selectedObject.geo && (
            <p style={{ margin: 0 }}>
              Geo coordinates: latitude <b>{selectedObject.geo[1].toFixed(4)}</b> <small>|</small> longitude <b>{selectedObject.geo[0].toFixed(4)}</b>
            </p>
          )}
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} onReset={handleClose} sx={{ mt: 1, width: '100%' }}>
            <TextField
              {...register('name')}
              margin="normal"
              required
              fullWidth
              placeholder="Name"
              type="text"
              aria-autocomplete="none"
              autoComplete="off"
              error={errors?.name ? true : false}
              helperText={errors?.name?.message?.toString()}
            />
            <TextField
              {...register('comment')}
              margin="normal"
              required
              multiline={true}
              rows={5}
              fullWidth
              placeholder="Comment"
              type="text"
              aria-autocomplete="none"
              autoComplete="off"
              error={errors?.comment ? true : false}
              helperText={errors?.comment?.message?.toString()}
            />

            <Box flex={1}>
              <Box flex={1}>
                <p style={{ margin: '0.5rem' }}>Access methods</p>
                <ToggleButtonGroup value={accessMethods} onChange={(_, values: string[]) => setAccessMethods(values)} aria-label="access-methods">
                  {ACCESS_METHODS_OPTONS.map(({ key, value, description }) => (
                    <Tooltip key={`access_methods_${key}`} title={description}>
                      <ToggleButton value={key} aria-label={value} selected={accessMethods.some(x => x === key)}>
                        {value}
                      </ToggleButton>
                    </Tooltip>
                  ))}
                </ToggleButtonGroup>
              </Box>

              <Box flex={1}>
                <p style={{ margin: '0.5rem' }}>Activation methods</p>
                <ToggleButtonGroup value={activationMethods} onChange={(_, values: string[]) => setActivationMethods(values)} aria-label="activation-methods">
                  {ACTIVATION_METHODS_OPTONS.map(({ key, value, description }) => (
                    <Tooltip key={`activation_methods_${key}`} title={description}>
                      <ToggleButton value={key} aria-label={value} selected={activationMethods.some(x => x === key)}>
                        {value}
                      </ToggleButton>
                    </Tooltip>
                  ))}
                </ToggleButtonGroup>
              </Box>

              <Box flex={1}>
                <p style={{ margin: '0.5rem' }}>Facilities</p>
                <ToggleButtonGroup value={facilities} onChange={(_, values: string[]) => setFacilities(values)} aria-label="facilities">
                  {FACILITIES_OPTONS.map(({ key, value, description }) => (
                    <Tooltip key={`facilities_${key}`} title={description}>
                      <ToggleButton value={key} aria-label={value} selected={facilities.some(x => x === key)}>
                        {value}
                      </ToggleButton>
                    </Tooltip>
                  ))}
                </ToggleButtonGroup>
              </Box>

              <Box textAlign="right">
                <Button type="reset" variant="contained" color="inherit" sx={{ mt: 3 }}>
                  Cancel
                </Button>
                <Button disabled={!isValid || async} type="submit" variant="contained" sx={{ mt: 3, ml: 2 }}>
                  Add spot
                </Button>
              </Box>
            </Box>
          </Box>
        </CommentFormContainer>
      </Modal>
    </>
  )
}
