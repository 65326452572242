/* eslint-disable @typescript-eslint/no-explicit-any */
import Modal from '@mui/material/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { asyncSelector } from '../comment/comment.selectors'
import { CommentFormContainer } from '../../../../../common/layout/Modal.styles'
import Avatar from '@mui/material/Avatar'
import WarningIcon from '@mui/icons-material/Warning'
import Box from '@mui/material/Box'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { addIssueSchema } from './issue.schema'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { getSelectedObject } from '../../aside.selectors'
import { AppDispatch } from '../../../../../store'
import { isIssueModalOpenSelector } from '../comment/comment.selectors'
import { addIssue, issueModalClose } from '../comment/comment.slice'
import { setSnackbar } from '../../../map.slice'

export const IssueModalComponent = () => {
  const dispatch = useDispatch<AppDispatch>()
  const isOpen = useSelector(isIssueModalOpenSelector)
  const async = useSelector(asyncSelector)
  const selectedObject = useSelector(getSelectedObject)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(addIssueSchema),
  })

  const onSubmit = async (payload: any) => {
    try {
      const { type, reference } = selectedObject!
      const comment = {
        message: payload.comment,
        reference,
        type,
      }

      dispatch(addIssue(comment))
      reset()
      dispatch(setSnackbar(`Your issue regarding ${selectedObject?.reference} has been reported`))
    } catch (e) {
      // console.log('ERR', e)
    }
  }

  const handleClose = () => {
    reset()
    dispatch(issueModalClose())
  }

  if (!isOpen) return

  return (
    <>
      <Modal keepMounted open={isOpen} onClose={handleClose} style={{ paddingLeft: '412px', height: '100%' }}>
        <CommentFormContainer elevation={3}>
          {/* <Component /> */}
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <WarningIcon />
          </Avatar>
          <h1>Report an issue / error</h1>
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} onReset={handleClose} sx={{ mt: 1, width: '100%' }}>
            <TextField
              {...register('comment')}
              margin="normal"
              required
              multiline={true}
              rows={5}
              fullWidth
              placeholder="Max 200 characters"
              type="text"
              aria-autocomplete="none"
              autoComplete="off"
              error={errors?.comment ? true : false}
              helperText={errors?.comment?.message?.toString()}
            />
            <Button type="reset" variant="contained" color="inherit" sx={{ mt: 3 }}>
              Cancel
            </Button>
            <Button disabled={!isValid || async} type="submit" variant="contained" sx={{ mt: 3, ml: 2 }}>
              Report
            </Button>
          </Box>
        </CommentFormContainer>
      </Modal>
    </>
  )
}
