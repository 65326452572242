/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { AdmMapContainerStyles } from '../../../map/Map.styles'
import { MapContainer, Polygon, TileLayer } from 'react-leaflet'
// import { replace } from '../../../../services/utils'

const itu: any = []

export const ItuZonesPage = () => {
  const [selectedArea, setSelectedArea] = useState<any>(JSON.parse(JSON.stringify(itu[0])))
  const [textareaValue, setTextAreaValue] = useState<string>('')

  const onLoad = () => setSelectedArea(JSON.parse(textareaValue))

  // console.log(selectedArea)
  return (
    <AdmMapContainerStyles>
      <div className="aside">
        <h1>ITU zones</h1>
        <textarea value={textareaValue} onChange={e => setTextAreaValue(e.target.value)} rows={10}></textarea>
        <button onClick={onLoad}>Load</button>
      </div>
      <MapContainer center={[0, 0]} zoom={2} scrollWheelZoom={true}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {selectedArea && <Polygon pathOptions={{ fillColor: 'yellow', color: 'red' }} positions={selectedArea.geometry.coordinates} />}
      </MapContainer>
    </AdmMapContainerStyles>
  )
}
