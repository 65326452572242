import { createSelector } from '@reduxjs/toolkit'
import { CommentState } from './comment.slice'
import { RootState } from '../../../../../store'

const selectCommentState = (state: RootState) => state.comment

export const asyncSelector = createSelector(selectCommentState, (state: CommentState) => state.async)

export const isCommentModalOpenSelector = createSelector(selectCommentState, (state: CommentState) => state.isModalOpen)

export const isIssueModalOpenSelector = createSelector(selectCommentState, (state: CommentState) => state.isIssueModalOpen)
