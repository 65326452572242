/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Park, ParkActivation, ParkArea, ParkDetails, ParkRelation, SummitWithin } from './parks.model'
import { http } from '../../../common/http/http.service'
import { parksService } from './parks.service'
import { LatLngBounds } from '../../../common/models/geo.model'
import { leafletBoundsToString } from '../../../services/utils'
import { Comment } from '../aside/components/comment/comment.model'

export interface ParksState {
  loadingParks: boolean
  loadingAreas: boolean
  loadingDetails: boolean
  loadingRelations: boolean
  loadingComments: boolean
  loadingSummitsWithin: boolean
  selectedParkDetails: ParkDetails | null
  selectedParkActivations: ParkActivation[] | null
  selectedParkRelations: ParkRelation[] | null
  selectedParkComments: Comment[] | null
  selectedParkSummitsWithin: SummitWithin[] | null
  parksForBounds: Park[]
  parkAreasForBounds: ParkArea[]
}

const initialState: ParksState = {
  loadingParks: false,
  loadingAreas: false,
  loadingDetails: false,
  loadingRelations: false,
  loadingComments: false,
  loadingSummitsWithin: false,
  selectedParkDetails: null,
  selectedParkActivations: null,
  selectedParkRelations: null,
  selectedParkComments: null,
  selectedParkSummitsWithin: null,
  parksForBounds: [],
  parkAreasForBounds: [],
}

export const getParksForBounds = createAsyncThunk('getParksForBounds', async (bounds: LatLngBounds) => {
  // console.log('*** getParksForBounds')
  const response = await http().get(`/parks?${leafletBoundsToString(bounds)}`)
  return response.data
})

export const getParkAreasForBounds = createAsyncThunk('getParkAreassForBounds', async (bounds: LatLngBounds) => {
  const response = await http().get(`/parks/areas?${leafletBoundsToString(bounds)}`)
  return response.data
})

export const getParkDetails = createAsyncThunk('getParkDetails', async (reference: string) => {
  const response = await http().get(`/parks/${reference}`)
  return response.data
})

export const getParkRelations = createAsyncThunk('getParkRelations', async (reference: string) => {
  const response = await http().get(`/parks/${reference}/relations`)
  return response.data
})

export const getSummitsWithin = createAsyncThunk('getSummitsWithin', async (reference: string) => {
  const response = await http().get(`/parks/${reference}/summits`)
  return response.data
})

export const getParksComments = createAsyncThunk('getParksComments', async (reference: string) => {
  const response = await http().get(`/parks/${reference}/comments`)
  return response.data
})

export const parksState = createSlice({
  name: 'parks',
  initialState,
  reducers: {
    setSelectedParkStore: () =>
      // state: ParksState, action: PayloadAction<Park>
      {
        // console.log('*** setSelectedParkStore')
        // console.log(action, state)
        // state.loaded = true
        // state.selectedPark = action.payload
      },
    clearSelecteParkStore: (state: ParksState) => {
      // console.log('*** clearSelectedParkStore')
      state.selectedParkDetails = null
    },
  },
  extraReducers: builder => {
    builder.addCase(getParksForBounds.pending, (state: ParksState) => {
      state.loadingParks = true
    })
    builder.addCase(getParksForBounds.fulfilled, (state: ParksState, action: PayloadAction<any>) => {
      const { payload } = action

      state.loadingParks = false
      state.parksForBounds = payload
    })
    builder.addCase(getParksForBounds.rejected, (state: ParksState) => {
      state.loadingParks = false
    })
    builder.addCase(getParkAreasForBounds.pending, (state: ParksState) => {
      state.loadingAreas = true
    })
    builder.addCase(getParkAreasForBounds.fulfilled, (state: ParksState, action: PayloadAction<any>) => {
      const { payload } = action
      state.loadingAreas = false
      state.parkAreasForBounds = payload
    })
    builder.addCase(getParkAreasForBounds.rejected, (state: ParksState) => {
      state.loadingAreas = false
    })
    builder.addCase(getParkDetails.pending, (state: ParksState) => {
      state.loadingDetails = true
    })
    builder.addCase(getParkDetails.fulfilled, (state: ParksState, action: PayloadAction<any>) => {
      const { payload } = action

      state.selectedParkDetails = parksService.getParkDataFromResponse(payload)
      state.selectedParkActivations = parksService.getParkActivationsFromResponse(payload)
      state.loadingDetails = false
    })
    builder.addCase(getParkDetails.rejected, (state: ParksState) => {
      state.loadingDetails = false
    })
    builder.addCase(getParkRelations.pending, (state: ParksState) => {
      state.loadingRelations = true
    })
    builder.addCase(getParkRelations.fulfilled, (state: ParksState, action: PayloadAction<ParkRelation[]>) => {
      const { payload } = action

      state.selectedParkRelations = parksService.getUniqueParkRelations(payload)
      state.loadingRelations = false
    })
    builder.addCase(getParkRelations.rejected, (state: ParksState) => {
      state.selectedParkRelations = []
      state.loadingRelations = false
    })
    builder.addCase(getParksComments.pending, (state: ParksState) => {
      state.loadingComments = true
    })
    builder.addCase(getParksComments.fulfilled, (state: ParksState, action: PayloadAction<any[]>) => {
      const { payload } = action

      state.loadingComments = false
      state.selectedParkComments = payload
    })
    builder.addCase(getParksComments.rejected, (state: ParksState) => {
      state.loadingComments = false
    })
    builder.addCase(getSummitsWithin.pending, (state: ParksState) => {
      state.loadingSummitsWithin = true
    })
    builder.addCase(getSummitsWithin.fulfilled, (state: ParksState, action: PayloadAction<SummitWithin[]>) => {
      const { payload } = action

      state.loadingSummitsWithin = false
      state.selectedParkSummitsWithin = payload
    })
    builder.addCase(getSummitsWithin.rejected, (state: ParksState) => {
      state.loadingSummitsWithin = true
    })
  },
})

export const { setSelectedParkStore, clearSelecteParkStore } = parksState.actions

export default parksState.reducer
