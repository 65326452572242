/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  z-index: 5000;
  left: 250px;
  bottom: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 8px;
  width: 400px;
  height: 40%;

  .list {
    margin: 12px 0;
    height: calc(100% - 50px);
    overflow-y: scroll;
  }

  input {
    width: 80%;
  }

  p {
    margin: 0;
    padding: 6px;
    font-family: 'Exo 2', sans-serif;
    font-size: 13px;

    &:hover {
      background-color: lightyellow;
      cursor: pointer;
    }

    &.current {
      color: red;
    }
  }
`

export const LoadFile = ({ onLoad }: any) => {
  const [searchTerm, setSearchTerm] = useState('')

  return (
    <Container>
      <div style={{ padding: '8px 4px', fontSize: '12px' }}>
        <input value={searchTerm} onChange={(e: any) => setSearchTerm(e.target.value)} />
        <button onClick={() => onLoad(searchTerm)}>Load</button>
      </div>
      <div className="list"></div>
    </Container>
  )
}

// http://localhost:3004/co/gadm41_POL_0.json
