/* eslint-disable react/jsx-no-target-blank */
import Modal from '@mui/material/Modal'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { ModalFormContainer } from '../../../common/layout/Modal.styles'
import { useDispatch, useSelector } from 'react-redux'
import { infoModalVisibleSelector } from '../map.selectors'
import { toggleInfoModal } from '../map.slice'

const InfoContaner = styled.div`
  h3 {
    text-align: center;
    small {
      font-weight: 300;
    }
  }
`

export const InfoModalComponent = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(infoModalVisibleSelector)
  const handleClose = () => dispatch(toggleInfoModal(false))

  return (
    <Modal keepMounted open={isOpen} onClose={handleClose} style={{ width: 'auto', height: '100%' }}>
      <InfoContaner>
        <ModalFormContainer>
          <h1>hammap.io</h1>
          <h3>
            an interactive map for hams
            <br />
            <small>(still work in progress)</small>
          </h3>

          <p>By the time I&apos;ll create the documentation feel free to watch some youtube videos I prepared:</p>
          <p>
            <a href="https://www.youtube.com/watch?v=mCTdsB3PoEI" target="_blank">
              Part 1 - Introduction
            </a>
          </p>
          <p>
            <a href="https://www.youtube.com/watch?v=pFdYaDos-A0" target="_blank">
              Part 2 - Going deeper
            </a>
          </p>
          <p>
            <a href="https://www.youtube.com/watch?v=4fII7iGRIgE&" target="_blank">
              Part 3 - More features
            </a>
          </p>
          <p>Best! 73!</p>
          <p>Marcin SP5ORI</p>
          <Box sx={{ mt: 3 }}>
            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </ModalFormContainer>
      </InfoContaner>
    </Modal>
  )
}
