/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useEffect, useState } from 'react'
import { usersAdmService } from './users.service'

export const UsersPage = () => {
  const [items, setItems] = useState<any[]>([])

  useEffect(() => {
    const getUsers = async () => {
      const data = await usersAdmService.getAll()
      setItems(data)
    }
    getUsers()
  }, [])

  return (
    <Box sx={{ padding: 2 }}>
      <h1 style={{ marginTop: 0, marginBottom: '2rem' }}>Application users</h1>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Callsign</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Created at</TableCell>
              <TableCell>Updated at</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(row => (
              <TableRow key={row.email} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {row.email}
                </TableCell>
                <TableCell>{row.callsign}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.createdAt}</TableCell>
                <TableCell>{row.updatedAt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
