import { useDispatch, useSelector } from 'react-redux'
import { getSnackbar } from '../../areas/map/map.selectors'
import Snackbar from '@mui/material/Snackbar'
import { clearSnackbar } from '../../areas/map/map.slice'

export const SnackbarComponent = () => {
  const dispatch = useDispatch()
  const snackbar = useSelector(getSnackbar)
  const handleClose = () => {
    dispatch(clearSnackbar())
  }

  return (
    <Snackbar
      open={!!snackbar}
      onClose={handleClose}
      message={snackbar?.message}
      autoHideDuration={snackbar?.duration || 2000}
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      // TransitionComponent={state.Transition}
      // key={state.Transition.name}
    />
  )
}
