import * as yup from 'yup'

export const signInSchema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required()

export const resetPasswordRequestSchema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required()

export const signUpSchema = yup
  .object({
    email: yup.string().email().required(),
    callsign: yup.string().min(4).max(20).required(),
    password: yup.string().required().min(8),
  })
  .required()

export const changePasswordSchema = yup
  .object({
    password: yup.string().required().min(8),
    passwordConfirm: yup
      .string()
      .required()
      .min(8)
      .oneOf([yup.ref('password')]),
  })
  .required()
