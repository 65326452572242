/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
// /* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from 'react'
import * as L from 'leaflet'

import { HeaderArea } from './components/HeaderArea'
import { FooterArea } from './components/FooterArea'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { setBoundsStore, setZoomLevelStore } from './map.slice'
import { debounce } from '../../services/utils'
import { Const } from '../../common'
import { AsidePanel } from './aside/AsidePanel'
import { clearSelectedObject, setSelectedObject, updateSelectedObjectGeo } from './aside/aside.slice'
import { SelectedObjectType } from './aside/aside.model'
import { ModalAuthenticationComponent } from '../auth/components/Modal.component'
import { mapService } from './map.service'
import { getParkAreasForBounds, getParksForBounds } from './parks/parks.slice'
import { parkAreasForBoundsSelector, parksForBoundsSelector } from './parks/parks.selectors'
import { summitsForBoundsSelector } from './summits/summits.selectors'
import { getSummitsForBounds } from './summits/summits.slice'
import { getVoivodeshipsForBounds } from './voivodeships/voivodeships.slice'
import { voivodeshipsForBoundsSelector } from './voivodeships/voivodeships.selectors'
import { getCountriesForBounds } from './countries/countries.slice'
import { countriesForBoundsSelector } from './countries/countries.selectors'
import { getSelectedObject } from './aside/aside.selectors'
import { Park } from './parks/parks.model'
import { parksService } from './parks/parks.service'
import { voivodeshipService } from './voivodeships/voivodeships.service'
import { Voivodeship } from './voivodeships/voivodeships.model'
import { Summit } from './summits/summits.model'
import { summitsService } from './summits/summits.service'
import { PointComponent } from './point/Point.component'
import { explorePoint } from './search/search.slice'
import { GeoPoint } from '../../common/models/geo.model'
import { SnackbarComponent } from '../../common/components/Snackbar.component'
import { countriesService } from './countries/countries.service'
import { getFlagsSelector } from './flags/flags.selectors'
import { getFlags } from './flags/flags.slice'
import { CommentModalComponent } from './aside/components/comment/CommentsModal.component'
import { IssueModalComponent } from './aside/components/issue/IssueModal.component'
import { Country } from './countries/countries.model'
import { ActivationSpotModalComponent } from './aside/components/spot/SpotModal.component'
import { getSpotsForBounds } from './spots/spots.slice'
import { spotsForBoundsSelector } from './spots/spots.selectors'
import { ActivationSpot } from './spots/spots.model'
import { spotsService } from './spots/spots.service'
import { isLoggedInSelector } from '../user/user.selectors'
import { InfoModalComponent } from './components/InfoModal.component'

// const INITIAL_POSITION: L.LatLngExpression = [52.436667, 20.873333]
const coords = localStorage.getItem('coords')
const INITIAL_POSITION: L.LatLngExpression = coords ? JSON.parse(coords) : [0, 0]

export const MainMap = () => {
  const dispatch = useDispatch<AppDispatch>()
  const map = useRef<L.Map>()

  const zoom = useSelector((state: RootState) => state.map.zoom)
  const bounds = useSelector((state: RootState) => state.map.bounds)

  let parksMarkersLayer: L.FeatureGroup
  let spotsMarkersLayer: L.FeatureGroup
  let summitsMarkersLayer: L.FeatureGroup
  let parksAreasLayer: L.FeatureGroup
  let voivodeshipsAreasLayer: L.FeatureGroup
  let countriesAreasLayer: L.FeatureGroup
  let markersLayer: L.FeatureGroup

  // TODO !!!!!!!!!!!!!!!!!!!!!!!!!
  const [parksCircleMarkerArray, setParksCircleMarkerArray] = useState<L.CircleMarker[]>([])
  const [spotsCircleMarkerArray, setSpotsCircleMarkerArray] = useState<L.CircleMarker[]>([])
  const [parksGeoArray, setParksGeoArray] = useState<any[]>([])
  const [summitsCircleMarkerArray, setSummitsCircleMarkerArray] = useState<L.CircleMarker[]>([])
  const [voivodeshipsGeoArray, setVoivodeshipsGeoArray] = useState<any[]>([])
  const [countriesGeoArray, setCountriesGeoArray] = useState<any[]>([])
  const [markersArray, setMarkersArray] = useState<L.Marker[]>([])
  // TODO !!!!!!!!!!!!!!!!!!!!!!!!!

  const isLoggedIn = useSelector(isLoggedInSelector)
  const selectedObject = useSelector(getSelectedObject)
  const parks = useSelector(parksForBoundsSelector)
  const spots = useSelector(spotsForBoundsSelector)
  const parkAreas = useSelector(parkAreasForBoundsSelector)
  const summits = useSelector(summitsForBoundsSelector)
  const voivodeships = useSelector(voivodeshipsForBoundsSelector)
  const countries = useSelector(countriesForBoundsSelector)
  const flags = useSelector(getFlagsSelector)

  useEffect(() => {
    // console.log('MainMap useEffect')
    // console.log('bounds', bounds)
    // console.log('zoom', zoom)
    if (bounds && zoom >= 9) {
      dispatch(getParksForBounds(bounds!))
      dispatch(getSummitsForBounds(bounds!))
    }

    if (!isLoggedIn) return

    if (bounds && zoom >= 11) {
      dispatch(getParkAreasForBounds(bounds!))
    }
    if (bounds && zoom >= 9) {
      dispatch(getSpotsForBounds(bounds!))
    }
    if (zoom >= 7 && zoom <= 8) {
      dispatch(getVoivodeshipsForBounds(bounds!))
    }
    if (zoom >= 3 && zoom <= 6) {
      dispatch(getCountriesForBounds(bounds!))
    }
  }, [bounds, isLoggedIn])

  useEffect(() => {
    if (!map.current) {
      return
    }
    if (parks && parks.length) {
      parksMarkersLayer = new L.FeatureGroup()

      parksCircleMarkerArray.forEach((x: L.CircleMarker) => x.remove())
      parksGeoArray.forEach((x: any) => x.remove())

      if (zoom < 9 || zoom >= 18) {
        return
      }

      if (zoom >= 9 && zoom < 18) {
        parks.forEach((park: Park) => {
          // console.log(parks.find(x => x.reference === 'SP-0654'))
          const parkMarker = parksService.getMarkerForPark(park, zoom, selectedObject, flags, onParkMarkerClick)
          setParksCircleMarkerArray((curr: any) => {
            curr.push(parkMarker)
            return curr
          })
          parkMarker.addTo(parksMarkersLayer)
        })
        map.current.addLayer(parksMarkersLayer)
      }
    }
  }, [parks, zoom, selectedObject, flags])

  useEffect(() => {
    if (!map.current) {
      return
    }

    if (spots && spots.length) {
      spotsMarkersLayer = new L.FeatureGroup()

      spotsCircleMarkerArray.forEach((x: L.CircleMarker) => x.remove())
      // parksGeoArray.forEach((x: any) => x.remove())

      if (zoom < 9 || zoom >= 18) {
        return
      }

      if (zoom >= 9 && zoom < 18) {
        spots.forEach((spot: ActivationSpot) => {
          // console.log(parks.find(x => x.reference === 'SP-0654'))
          const spotMarker = spotsService.getMarkerForSpot(spot, zoom, selectedObject, flags, onSpotMarkerClick)
          setSpotsCircleMarkerArray((curr: any) => {
            curr.push(spotMarker)
            return curr
          })
          spotMarker.addTo(spotsMarkersLayer)
        })
        map.current.addLayer(spotsMarkersLayer)
      }
    }
  }, [spots, zoom, selectedObject, flags])

  useEffect(() => {
    if (!map.current) {
      return
    }

    if (parkAreas && parkAreas.length && parks && parks.length) {
      parksAreasLayer = new L.FeatureGroup()
      parksGeoArray.forEach((x: any) => x.remove())

      if (zoom >= 11) {
        parkAreas.forEach((parkArea: any) => {
          const parkData = parks.find(p => p.reference === parkArea.reference)
          if (parkData) {
            const geo: any = parksService.getAreaForPark(parkArea, parkData, zoom, selectedObject, onParkAreaClick)
            setParksGeoArray((curr: any) => {
              curr.push(geo)
              return curr
            })
            geo.addTo(parksAreasLayer)
          }
        })
        map.current.addLayer(parksAreasLayer)
      }
    }
  }, [parkAreas, parks, zoom, selectedObject, flags])

  useEffect(() => {
    if (!map.current) {
      return
    }

    if (summits && summits.length) {
      summitsMarkersLayer = new L.FeatureGroup()

      summitsCircleMarkerArray.forEach((x: L.CircleMarker) => x.remove())

      if (zoom < 9 || zoom >= 18) {
        return
      }

      if (zoom >= 9 && zoom < 15) {
        summits.forEach((summit: Summit) => {
          const summitMarker = summitsService.getMarkerForSummit(summit, zoom, selectedObject, flags, onSummitMarkerClick)
          setSummitsCircleMarkerArray((curr: any) => {
            curr.push(summitMarker)
            return curr
          })
          summitMarker.addTo(summitsMarkersLayer)
        })
        map.current.addLayer(summitsMarkersLayer)
      }
    }
  }, [summits, zoom, selectedObject, flags])

  useEffect(() => {
    if (!map.current) {
      return
    }

    if (voivodeships && voivodeships.length) {
      voivodeshipsAreasLayer = new L.FeatureGroup()

      voivodeshipsGeoArray.forEach((x: any) => x.remove())

      if (zoom >= 7 && zoom <= 8) {
        voivodeships.forEach((voivodeship: any) => {
          const voivodeshipArea = voivodeshipService.getAreaForVoivodeship(voivodeship, zoom, selectedObject, flags, onVoivodeshipAreaClick)
          setVoivodeshipsGeoArray((curr: any) => {
            curr.push(voivodeshipArea)
            return curr
          })
          voivodeshipArea.addTo(voivodeshipsAreasLayer)
        })
        map.current.addLayer(voivodeshipsAreasLayer)
      }
    }
  }, [voivodeships, zoom, selectedObject, flags])

  useEffect(() => {
    if (!map.current) {
      return
    }
    if (countries && countries.length) {
      countriesAreasLayer = new L.FeatureGroup()

      countriesGeoArray.forEach((x: any) => x.remove())

      if (zoom >= 3 && zoom <= 6) {
        countries.forEach((country: any) => {
          const countryArea = countriesService.getAreaForCountry(country, zoom, selectedObject, flags, onCountryAreaClick)
          setCountriesGeoArray((curr: any) => {
            curr.push(countryArea)
            return curr
          })
          countryArea.addTo(countriesAreasLayer)
        })
        map.current.addLayer(countriesAreasLayer)
      }
    }
  }, [countries, zoom, selectedObject, flags])

  useEffect(() => {
    const mapCurrent = map.current
    //remove marker from exprore point function
    if (selectedObject?.type !== SelectedObjectType.POINT) {
      markersArray.forEach((x: L.Marker) => x.remove())
    }

    if (mapCurrent && selectedObject && selectedObject.geo) {
      setTimeout(() => {
        const centerPoint = mapService.getCenterPoint(window.innerWidth, mapCurrent.getBounds(), selectedObject.geo!, true)

        setTimeout(() => {
          map.current?.panTo(centerPoint)
        }, Const.debounceTime)

        if (selectedObject.type === SelectedObjectType.COUNTRY) {
          map.current?.setZoom(6)
        } else if (selectedObject.type === SelectedObjectType.VOIVODESHIP) {
          map.current?.setZoom(8)
        } else if ((selectedObject.type === SelectedObjectType.PARK || selectedObject.type === SelectedObjectType.SUMMIT) && zoom <= 8) {
          map.current?.setZoom(10)
        }
      }, Const.debounceTime)
    }
  }, [selectedObject])

  const onParkMarkerClick = (park: Park) => {
    dispatch(setSelectedObject({ type: SelectedObjectType.PARK, reference: park.reference, name: park.name, geo: park.coords.coordinates }))
  }

  const onSpotMarkerClick = (spot: ActivationSpot) => {
    dispatch(setSelectedObject({ type: SelectedObjectType.SPOT, reference: spot.reference, name: spot.name, geo: spot.coords.coordinates }))
  }

  const onSummitMarkerClick = (summit: any) => {
    // console.log('Summit clicked', summit)
    dispatch(setSelectedObject({ type: SelectedObjectType.SUMMIT, reference: summit.summitCode, name: summit.name, geo: summit.coords.coordinates }))
    // map.current?.panTo([summit.coords.coordinates[1], summit.coords.coordinates[0]])
  }

  const onParkAreaClick = (parkArea: any) => {
    dispatch(setSelectedObject({ type: SelectedObjectType.PARK, reference: parkArea.reference, name: parkArea.name, geo: parkArea.coords.coordinates }))
  }

  const onVoivodeshipAreaClick = (voivodeship: Voivodeship) => {
    dispatch(
      setSelectedObject({
        type: SelectedObjectType.VOIVODESHIP,
        reference: voivodeship.potaAbbreviation,
        name: voivodeship.name,
        geo: voivodeship.coords.coordinates,
      })
    )
  }

  const onCountryAreaClick = (country: Country) => {
    dispatch(
      setSelectedObject({
        type: SelectedObjectType.COUNTRY,
        reference: country.prefix,
        name: country.country,
        geo: undefined,
      })
    )
  }

  const onPointInitializeClick = () => {
    if (selectedObject && selectedObject.type === SelectedObjectType.POINT) {
      dispatch(clearSelectedObject())
    } else {
      dispatch(setSelectedObject({ type: SelectedObjectType.POINT, reference: 'explore', name: `Click and drag the marker to the place you're interested in` }))
      setTimeout(() => {
        if (map && map.current) {
          const center = map.current.getCenter()
          // const centerPoint = mapService.getCenterPoint(window.innerWidth, map.current.getBounds(), [center.lng, center.lat], true)
          markersLayer = new L.FeatureGroup()
          const marker = L.marker(center, {
            draggable: true,
            autoPan: true,
          }).addEventListener('dragend', e => {
            // console.log('drag', e)
            // console.log(e.target._latlng)
            // const geo = [e.target._latlng.lng, e.target._latlng.lat]
            // const point = e.target._latlng
            // map.current?.panTo(point)
            const xy: GeoPoint = [e.target._latlng.lng, e.target._latlng.lat]
            dispatch(updateSelectedObjectGeo(xy))
            dispatch(explorePoint(xy))
          })
          setMarkersArray((curr: any) => {
            curr.push(marker)
            return curr
          })
          // .addTo(map.current)
          marker.addTo(markersLayer)
          map.current.addLayer(markersLayer)
        }
      }, Const.debounceTime)
    }
    // map.current?.on('click', onMapClick)
  }

  useEffect(() => {
    const mapNode = document.getElementById('main-map') as HTMLDivElement | null
    if (!mapNode || map.current) {
      return
    } else {
      map.current = L.map(mapNode, {
        // zoomAnimation: true
        zoomControl: false,
        attributionControl: true,
      })
        .setView(INITIAL_POSITION)
        .setZoom(zoom)
        .addEventListener(
          'zoomend',
          debounce(() => {
            if (map.current) dispatch(setBoundsStore(map.current.getBounds()))
          }, Const.debounceTime)
        )
        .addEventListener(
          'moveend',
          debounce(() => {
            if (map.current) dispatch(setBoundsStore(map.current.getBounds()))
          }, Const.debounceTime)
        )
        .addEventListener('zoomend', (e: any) => {
          dispatch(setZoomLevelStore(e.target._zoom))
        })
      L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      }).addTo(map.current!)
      L.control
        .zoom({
          position: 'bottomright',
        })
        .addTo(map.current)
      setTimeout(() => {
        if (map.current) {
          dispatch(setBoundsStore(map.current.getBounds()))

          // GEOLOCATION
          map.current.whenReady(() => {
            navigator.geolocation.getCurrentPosition(position => {
              if (!position || !position.coords) return

              const { latitude, longitude } = position.coords
              const coords: L.LatLngExpression = [latitude, longitude]
              localStorage.setItem('coords', JSON.stringify(coords))
              map.current?.setZoom(10)
              map.current?.panTo(coords)
            })
          })
        }
      }, 1000)
    }

    dispatch(getFlags())
  }, [])

  return (
    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
      <div className="mobile-info">Mobile view is not supported yet</div>
      <div id="main-map" style={{ flex: 1, height: '100vh' }}>
        <FooterArea />
      </div>
      <AsidePanel addActivationSpotClick={onPointInitializeClick} />
      <PointComponent pointClickInitialize={onPointInitializeClick} />
      <HeaderArea />
      <SnackbarComponent />
      <ModalAuthenticationComponent />
      <CommentModalComponent />
      <IssueModalComponent />
      <ActivationSpotModalComponent />
      <InfoModalComponent />
    </div>
  )
}
