export enum HttpCode {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  UNPROCESSABLE_ENTITY = 422,
  SERVER_ERROR = 500,
}

interface HttpErrorResponse {
  status: HttpCode
  data: {
    message: string
    details: string
  }
}

export interface HttpError {
  response: HttpErrorResponse
}
