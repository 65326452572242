import styled from 'styled-components'
import { SearchComponent } from '../search/SearchComponent'
import { AvatarComponent } from './AvatarComponent'
import { InfoComponent } from './Info.component'

const Container = styled.div`
  position: absolute;
  width: 100vw;
  height: 60px;
  top: 0;
  left: 0;
  z-index: 500;
  background-image: linear-gradient(
    to bottom,
    hsla(0, 0%, 12.94%, 0.9) 0%,
    hsla(0, 0%, 12.94%, 0.889) 8%,
    hsla(0, 0%, 12.94%, 0.857) 15.4%,
    hsla(0, 0%, 12.94%, 0.808) 22.3%,
    hsla(0, 0%, 12.94%, 0.745) 28.7%,
    hsla(0, 0%, 12.94%, 0.671) 34.8%,
    hsla(0, 0%, 12.94%, 0.588) 40.7%,
    hsla(0, 0%, 12.94%, 0.501) 46.4%,
    hsla(0, 0%, 12.94%, 0.412) 52.1%,
    hsla(0, 0%, 12.94%, 0.325) 57.9%,
    hsla(0, 0%, 12.94%, 0.241) 63.9%,
    hsla(0, 0%, 12.94%, 0.166) 70.1%,
    hsla(0, 0%, 12.94%, 0.101) 76.7%,
    hsla(0, 0%, 12.94%, 0.049) 83.9%,
    hsla(0, 0%, 12.94%, 0.015) 91.6%,
    hsla(0, 0%, 12.94%, 0) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    flex: 1;

    &.left {
      /* margin-left: 8px; */

      form {
        max-width: 370px;
      }
      // header {
      //   font-weight: 900;
      //   font-size: 32px;
      //   color: rgba(0, 0, 0, 0.7);
      //   margin-left: 2rem;
      // }
    }
    &.middle {
      // text-align: center;
      // form {
      //   width: 100%;
      // }
    }
    &.right {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      /* padding-top: 3rem; */
      margin-top: 4rem;

      & > div {
        display: flex;
        justify-content: flex-end;
      }

      .MuiAvatar-root {
        margin-top: 0.5rem;
        margin-right: 0.5rem;
      }

      .MuiChip-root {
        margin-top: 1.25rem;
        margin-right: 0.5rem;
        opacity: 0;
        animation: chipbounce 3s ease;
      }

      @keyframes chipbounce {
        0% {
          opacity: 0;
          transform: translateX(0);
        }
        20% {
          opacity: 1;
        }
        25% {
          transform: translateX(30px);
        }
        30% {
          transform: translateX(-30px);
        }
        35% {
          transform: translateX(30px);
        }
        40% {
          transform: translateX(-30px);
        }
        45% {
          transform: translateX(0px);
        }
        95% {
          transform: translateX(0px);
          opacity: 1;
        }
        100% {
          transform: translateX(200px);
          opacity: 0;
          /* display: none; */
          /* visibility: hidden; */
        }
      }
    }
  }
`

export const HeaderArea = () => {
  return (
    <Container>
      <div className="left">
        {/* <header>MAPP</header> */}
        <SearchComponent />
      </div>
      <div className="middle"></div>
      <div className="right">
        <AvatarComponent />
        <InfoComponent />
      </div>
    </Container>
  )
}
