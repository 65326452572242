import { useEffect, useState } from 'react'

export const useDebounce = (value: string | number, time = 500) => {
  // if (value === undefined) return

  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timeoutRefId = setTimeout(() => {
      setDebouncedValue(value)
    }, time)

    return () => {
      clearTimeout(timeoutRefId)
    }
  }, [value, time])

  return debouncedValue
}
